// TODO: be carefull to update
// 'connected-react-router' package from version higher than 6.3.1,
// there is issue with query parameter dissaper first
// time load
// https://github.com/supasate/connected-react-router/issues/259
// https://github.com/supasate/connected-react-router/issues/338

import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory, createMemoryHistory } from 'history';
import { applyMiddleware, compose, createStore as reduxCreateStore } from 'redux';
import thunkMiddleware from 'redux-thunk';

import { gameEventMiddleware } from './middlewares';
import createRootReducer from './reducers';
import { MiscUtils } from '../../utils/MiscUtils';

const composeEnhancers =
  (typeof window !== 'undefined' && (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

export const createStore = function (url = '/') {
  const { isServer } = MiscUtils;
  /** Create a history depending on the environment */
  const history = isServer ? createMemoryHistory({ initialEntries: [url] }) : createBrowserHistory();
  const initialState = !isServer ? (window as any).__INITIAL_STATE__ : {};
  const middlewares = [thunkMiddleware, routerMiddleware(history), gameEventMiddleware];
  const store = reduxCreateStore(
    createRootReducer(history),
    initialState,
    composeEnhancers(applyMiddleware(...middlewares))
  );

  return { store, history };
};

export const createTestStore = function (url = '/', initialState: {}) {
  const { isServer } = MiscUtils;
  /** Create a history depending on the environment */
  const history = isServer ? createMemoryHistory({ initialEntries: [url] }) : createBrowserHistory();
  const middlewares = [thunkMiddleware, routerMiddleware(history)];
  const store = reduxCreateStore(
    createRootReducer(history),
    initialState,
    composeEnhancers(applyMiddleware(...middlewares))
  );

  return { store, history };
};

export const reduxStore = createStore();
export type AppState = ReturnType<typeof reduxStore.store.getState>;
