import React, { FC, useEffect, useState } from 'react';

import classNames from 'classnames';
import { useTranslation } from "react-i18next";

import styles from './MonthlyCard.css';
import { Button } from '../../FigmaStyleguide/Button/Button';
import { SubscriptionPlan } from '../../models/Subscription/SubscriptionData';
import { ManageType } from '../../organisms/HeaderSideMenu/SubscriptionTab/ManagePlan/ManagePlan';

type MonthlyCardProps = {
  btnLabel: string;
  manageType?: ManageType;
  managePlanStyles?: boolean;
  plan: SubscriptionPlan;
  handlerBtn: () => void;
};
export const MonthlyCard: FC<MonthlyCardProps> = React.memo(
  ({ btnLabel, managePlanStyles, manageType, plan, handlerBtn }: MonthlyCardProps) => {
    MonthlyCard.displayName = 'MonthlyCard';

    const { t } = useTranslation();
    const [planPriceLabel, setPlanPriceLabel] = useState<number>(null);
    const [currencySign, setCurrencySign] = useState<string>('');

    useEffect(() => {
      const planFullPrice = plan?.currencies[0]?.unitAmount;
      const planPriceValue = planFullPrice / plan?.intervalLength;
      const planLabel = Math.floor(planPriceValue * 100) / 100;
      const planCurrency = plan?.currencies[0]?.currency === 'USD' ? '$' : ''; // create a helper for getting currencies signs

      setPlanPriceLabel(planLabel);
      setCurrencySign(planCurrency);
    }, []);

    return (
      <div
        className={classNames(
          styles.container,
          managePlanStyles && styles.managePlanStyles,
          manageType === ManageType.UPDATE && styles.managePlanUpdate
        )}
      >
        <div className={styles.info}>
          <div className={styles.valuesRow}>
            <div className={styles.heading}>{t('PAYMENT_CARD.MONTHLY')}</div>
            <div>
              <span className={styles.price}>
                {currencySign}
                {planPriceLabel}
              </span>
              &nbsp;
              <span className={styles.monthLabel}>{t('PAYMENT_CARD.MONTH_PERIOD')}</span>
            </div>
          </div>
        </div>
        {manageType !== ManageType.UPDATE && (
          <div className={classNames(styles.btnRow, styles.btnRowMonthly)}>
            <Button outlined className={styles.btnSubscribe} onClick={handlerBtn}>
              {btnLabel}
            </Button>
          </div>
        )}
      </div>
    );
  }
);
