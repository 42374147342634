import React from 'react';

import styles from './PlusGemsShopBanner.css';
import { UrlService } from '../../../../../services/UrlService';


const PlusGemsShopBanner = React.memo(() => {
  return (
    <div className={styles.container}>
      <img
        className={styles.image}
        src={`${UrlService.toCDNUrl('/images/plus_shop_banner.webp')}`}
        alt="Gems shop banner"
      />
    </div>
  );
});

PlusGemsShopBanner.displayName = 'PlusAppLoader';
export default PlusGemsShopBanner;
