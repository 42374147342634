import React from 'react';

import styles from './GemsShopBanner.css';
import { UrlService } from '../../services/UrlService';

const GemsShopBanner = React.memo(() => {
  return (
    <div className={styles.container}>
      <img
        className={styles.image}
        src={`${UrlService.toCDNUrl('/images/gems_shop_banner.png')}`}
        alt="Gems shop banner"
      />
    </div>
  );
});

GemsShopBanner.displayName = 'AppLoader';
export default GemsShopBanner;
