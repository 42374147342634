import React from 'react';

import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import { ButtonSignIn } from './ButtonSignIn';
import { SharedProps } from './MenuBody';
import styles from './styles.css';
import { MobileNavButton, mobileNavButtonsPropsData } from '../MobileNavigationUxRedesigned';
import { MobileNavButtonsNames, MobileNavButtonsProps } from '../MobileNavigationUxRedesigned.types';

export const ButtonsBlock = (props: SharedProps) => {
  const { activeButtonName, isItGamePage, isHomePage } = props;
  const dispatch = useDispatch();
  const plusFeatureFlag = useSelector(({ arkConfig }) => arkConfig?.plusFeature?.isEnabled);
  const currentLang = useSelector((state) => state.currentLang);
  const buttonsData: MobileNavButtonsProps = mobileNavButtonsPropsData(dispatch, isItGamePage, plusFeatureFlag);
  const buttons = [MobileNavButtonsNames.SHOP];

  buttons.push(currentLang === 'en' ? MobileNavButtonsNames.BLOG : MobileNavButtonsNames.SUPPORT);
  buttons.push(isItGamePage ? MobileNavButtonsNames.COLLECTIONS : MobileNavButtonsNames.ADVANTAGE);
  return (
    <div className={styles.ButtonsBlock}>
      <ButtonSignIn />
      {buttons.map((buttonName, index) =>
        MobileNavButton({
          ...buttonsData[buttonName],
          name: buttonName,
          indexKey: index,
          activeButtonName: activeButtonName,
          isItGamePage: isItGamePage,
          isHomePage: isHomePage,
          className: classNames(styles.Button, styles[`__Button_${buttonName}`]),
          isMenuButton: true
        })
      )}
    </div>
  );
};
