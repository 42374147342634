class CookieServiceProvider {
  public setArkCookie(name: string, value: unknown, days: number = 1825) {
    const d = new Date();

    d.setTime(d.getTime() + days * 24 * 60 * 60 * 1000);
    const expires = `expires=${d.toUTCString()}`;

    if (document) {
      document.cookie = `${name}=${value};${expires};path=/;`;
    }
  }

  public getArkCookie(name: string) {
    const match = document?.cookie?.match?.(new RegExp(`(^| )${name}=([^;]+)`));

    if (match) {
      return match[2];
    }

    return false;
  }

  public removeArkCookie(name: string) {
    if (document) {
      document.cookie = `${name}=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
      document.cookie = `${name}=; Domain=.${location.hostname}; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
      document.cookie = `${name}=; Domain=.arkadium.com; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
    }
  }
}

export const CookieService = new CookieServiceProvider();
