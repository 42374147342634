import React from 'react';

import { useDispatch } from 'react-redux';

import { I18nText } from '../../../../atoms/i18nText/i18nText';
import { HeaderSideMenuTabs } from '../../../../constants/HeaderSideMenuTabs';
import { Button } from '../../../../FigmaStyleguide/Button/Button';
import { setSideMenuActivePage } from '../../../../store/ducks/layout';
import styles from '../RightPanelCommonStyles/RightPanelCommonStyles.css';

export const StillNeedHelp = React.memo(() => {
    const dispatch = useDispatch();
    return (
        <div className={styles.stillHelpText}>
            <I18nText keyName={'REGISTER.STILL_NEED_HELP'} />
            <Button isLink onClick={() => dispatch(setSideMenuActivePage(HeaderSideMenuTabs.HELP_TAB))}>
                <I18nText keyName={'REGISTER.SUPPORT'} />
            </Button>
        </div>
    );
});
