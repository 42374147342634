import { Analytics } from './Analytics/Analytics';
import { MiscUtils } from '../../utils/MiscUtils';
import { environment } from '../config/environment';
import DisplayAdsNoRefreshGamesSlugs from '../constants/DisplayAdsNoRefreshGamesSlugs';
import { GameState } from '../models/Enums';
import { IGame } from '../models/Game/Game';
import { reduxStore } from '../store';

class AdsService {
    private adRefreshTimeMs: number;

    private lastRefreshDate: number;

    private adWasRefreshed = false;

    private displayAd: any = null;

    private currentGame: IGame | null = null;

    constructor() {
        this.adRefreshTimeMs = environment.AD_REFRESH_TIME_MS || 30000;
        this.lastRefreshDate = Date.now();
        this.initAdRefreshOnMouseMove();
    }

    setCurrentGame(game: IGame | null) {
        this.currentGame = game;
    }

    /* todo add onShow method when Ads devs adds api for that */
    public init() {
        if (!MiscUtils.isServer) {
            this.displayAd = document.createElement('display-ad-component');
            this.displayAd.coreService.onShowGTMEvent = this.onAdShow;
        }
    }

    public onAdShow = (ads) => {
        const delayedAdTrack = [];

        ads.forEach((adId) => {
            const adEl = document.getElementById(adId);

            if (this.adViewableAndHasSize(adEl)) {
                this.trackImpression(adEl);
            } else if (this.adNotViewableAndHasSize(adEl)) {
                delayedAdTrack.push(adEl);
            }
        });

        // TODO: this is a workaround to track ad impression more precise, but Ad team should
        // update ad library API to help us improve such kind of tracking
        // for example add some callback for 'Viewable' property updates
        if (delayedAdTrack.length) {
            this.trackDelayed(delayedAdTrack);
        }
    };

    public refresh() {
        const { gameState } = reduxStore.store.getState();

        if (gameState !== GameState.GAME) return;
        if (DisplayAdsNoRefreshGamesSlugs.includes(this.currentGame?.alias)) return;

        const dateNow = Date.now();

        // Refresh ads only if the refresh interval has elapsed
        if (dateNow - this.lastRefreshDate <= this.adRefreshTimeMs) return;

        const ads = Array.from(document.querySelectorAll('display-ad-component'));

        ads.forEach((ad: any) => {
            if (typeof ad.refresh === 'function') {
                ad.refresh();
            }
        });

        this.lastRefreshDate = dateNow;
        this.adWasRefreshed = true;
    }


    public forceRefresh() {
        const ads = Array.from(document.querySelectorAll('display-ad-component'));

        ads.forEach((ad: any) => {
            if (ad?.refresh) ad.refresh();
        });
        this.lastRefreshDate = Date.now();
        this.adWasRefreshed = true;
    }

    private initAdRefreshOnMouseMove() {
        if (!MiscUtils.isServer) {
            window.addEventListener('mousemove', () => {
                this.refresh();
            });
        }
    }

    private adViewableAndHasSize(el): boolean {
        if (
            el &&
            el.parentElement &&
            (el.parentElement as any).isViewable() &&
            el.offsetWidth > 0 &&
            el.offsetHeight > 0
        ) {
            return true;
        }

        return false;
    }

    private adNotViewableAndHasSize(el): boolean {
        if (
            el &&
            el.parentElement &&
            !(el.parentElement as any).isViewable() &&
            el.offsetWidth > 0 &&
            el.offsetHeight > 0
        ) {
            return true;
        }

        return false;
    }

    private trackDelayed(ads: any[]): void {
        setTimeout(() => {
            ads.forEach((adEl) => {
                if (this.adViewableAndHasSize(adEl)) {
                    this.trackImpression(adEl);
                }
            });
        }, 500);
    }

    private trackImpression(adEl: any): void {
        Analytics.trackEvent(
            Analytics.general.displayAd(
                this.adWasRefreshed,
                (adEl.parentElement as any).isViewable(),
                this.currentGame,
                [adEl.offsetWidth, adEl.offsetHeight],
                adEl.parentElement.getAttribute('data-id')
            )
        );
    }
}

export default new AdsService();
