import * as yup from 'yup';

import { TranslationService } from '../../../../../services/TranslationService';

export const validationSchema = yup.object().shape({
  password: yup
    .string()
    .required('Password is required')
    .matches(
      /^[A-Za-z0-9!@#$%^&*()_[\]{},.<>+=-]*$/,
      TranslationService.translateIt('REGISTER.YOUR_PASSWORD_CONTAINS')
    )
    .min(8)
    .matches(/(.*[A-Z].*)/, ' '),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password'), null], TranslationService.translateIt('REGISTER.SORRY_YOUR_PASSWORDS'))
    .required('Confirm password password is required')
});
export const passwordMinValidation = yup.string().min(8);
export const passwordUppercaseValidation = yup.string().matches(/(.*[A-Z].*)/);
export const defaultValues = {
  password: '',
  confirmPassword: ''
};
