import React, { FC, ReactElement, useEffect, useState } from 'react';

import { UserSubscription } from '@arkadium/eagle-payments-api-client/dist/types/api/v1/dto/subscription.dto';
import classNames from 'classnames';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { PlusAvatarCardBlock } from './components/PlusAvatarCardBlock/PlusAvatarCardBlock';
import PlusFAQBlock from './components/PlusFAQBlock/PlusFAQBlock';
import { PlusHistoryBlock } from './components/PlusHistoryBlock/PlusHistoryBlock';
import { PlusJoinBlock } from './components/PlusJoinBlock/PlusJoinBlock';
import { PlusStatsBlock } from './components/PlusStatsBlock/PlusStatsBlock';
import styles from './PlusPaymentAndSubscription.css';
import { globalErrorHandler } from '../../../../../../utils/LogUtils';
import { AppLoader } from '../../../../../atoms/AppLoader/AppLoader';
import { I18nText } from '../../../../../atoms/i18nText/i18nText';
import { NavLink } from '../../../../../atoms/Link/Link';
import { PaymentType, RecurlyPlans } from '../../../../../constants/RecurlyPurchase';
import {
	checkTrialPeriodAvailability,
	getPlanByCode, isTrialSubscriptionActive,
	SubscriptionPlans
} from '../../../../../constants/SubscriptionPlan';
import { SubscriptionPlan } from '../../../../../models/Subscription/SubscriptionData';
import PlusCard from '../../../../../organisms/HeaderSideMenu/PlusShopTab/components/PlusCard/PlusCard';
import { AppInsightService } from '../../../../../services/AppInsight';
import {
	getFirstDateSubscription,
	getLastDateSubscription,
	getType,
	removeInternal
} from '../../../../../services/PaymentAndSubscriptionHelpers';
import PaymentService from '../../../../../services/PaymentService';
import { UrlService } from '../../../../../services/UrlService';
import {
	setActiveUserSubscriptions,
	setExpiredUserSubscriptions
} from '../../../../../store/ducks/subscription/common';
import { AlreadySubscribedBlock } from '../AlreadySubscribedBlock/AlreadySubscribedBlock';
import { StatusBlock } from '../StatusBlock/StatusBlock';

export enum SubscriptionActions {
	NONE = 'none',
	STANDARD = 'standard',
	RENEW = 'renew',
	RESTART = 'restart',
	SUBSCRIBED_EXTERNAL = 'subscribed1',
	SUBSCRIBED_BOTH = 'subscribed2',
}

export const PlusPaymentAndSubscription: FC = React.memo(() => {
	const dispatch = useDispatch();
	const [loading, setLoading] = useState<boolean>(true);
	const activeSubscriptionFromRedux = useSelector(({ activeUserSubscriptions }) => activeUserSubscriptions || []);
	const expiredSubscriptionFromRedux = useSelector(({ expiredUserSubscriptions }) => expiredUserSubscriptions || []);
	const plans = useSelector(({ subscriptionPlans }) => subscriptionPlans);
	const plusFeatureFlag = useSelector(({ arkConfig }) => arkConfig?.plusFeature?.isEnabled);
	const [activeSubscriptions, setActiveSubscriptions] = useState<UserSubscription[]>(removeInternal(
		activeSubscriptionFromRedux));
	const [expiredSubscriptions, setExpiredSubscriptions] = useState<UserSubscription[]>(removeInternal(
		expiredSubscriptionFromRedux));
	const isAASubscription = activeSubscriptions.every(s => !s.planId.includes('aasub2025'));
	const isTrialAvailable = checkTrialPeriodAvailability(expiredSubscriptions);
	const isTrialActive = isTrialSubscriptionActive(activeSubscriptionFromRedux) && isTrialAvailable;
	const annualPlanName = RecurlyPlans(plusFeatureFlag)[PaymentType.subscription][!isTrialAvailable ?
		SubscriptionPlans.ANNUAL :
		SubscriptionPlans.ANNUAL_TRIAL].plan;
	const monthlyPlanName = RecurlyPlans(plusFeatureFlag)[PaymentType.subscription][!isTrialAvailable ?
		SubscriptionPlans.MONTHLY :
		SubscriptionPlans.MONTHLY_TRIAL].plan;

	useEffect(() => {
		setActiveSubscriptions(removeInternal(activeSubscriptionFromRedux));
	}, [activeSubscriptionFromRedux]);

	useEffect(() => {
		if (activeSubscriptionFromRedux?.length === 0) {
			setActiveSubscriptions([]);
			PaymentService.getSubscriptions()
				.then((res) => {
					setActiveSubscriptions(removeInternal(res));
					dispatch(setActiveUserSubscriptions(res));
					setLoading(false);
				})
				.catch((err) => {
					globalErrorHandler({
						error: err,
						filename: 'PaymentAndSubscription.tsx',
						info: 'PaymentService.getSubscriptions()'
					});
					setLoading(false);
					AppInsightService.trackAppError(err, { data: 'getSubscriptions()' });
				});
		} else {
			setLoading(false);
		}

		if (expiredSubscriptionFromRedux?.length === 0) {
			setExpiredSubscriptions([]);
			PaymentService.getExpiredSubscriptions()
				.then((res) => {
					setExpiredSubscriptions(removeInternal(res));
					dispatch(setExpiredUserSubscriptions(res));
					setLoading(false);
				})
				.catch((err) => {
					globalErrorHandler({ error: err, filename: 'PaymentAndSubscription.tsx', info: 'ExpiredSubscriptions()' });
					setLoading(false);
					AppInsightService.trackAppError(err, { data: 'getExpiredSubscriptions()' });
				});
		} else {
			setLoading(false);
		}
	}, []);

	let content: ReactElement;
	const type: SubscriptionActions = getType(activeSubscriptions, expiredSubscriptions);
	const sharedProps = {
		annualPlanName,
		monthlyPlanName,
		plans,
		subscriptionState: type === SubscriptionActions.NONE ? SubscriptionActions.NONE : SubscriptionActions.RESTART,
		isTrialAvailable,
		isTrialActive
	};

	switch (type) {
		case SubscriptionActions.NONE:
		case SubscriptionActions.RESTART:
			content = NoSubscriptionComponent(sharedProps);
			break;
		case SubscriptionActions.STANDARD:
			content = StandardComponent(activeSubscriptions, isTrialActive, isAASubscription);
			break;
		case SubscriptionActions.RENEW:
			content = RenewComponent(activeSubscriptions, isTrialActive, isAASubscription);
			break;
		case SubscriptionActions.SUBSCRIBED_EXTERNAL:
			content =
				SubscribedComponent(
					SubscriptionActions.SUBSCRIBED_EXTERNAL,
					activeSubscriptions,
					isTrialActive,
					isAASubscription
				);
			break;
		case SubscriptionActions.SUBSCRIBED_BOTH:
			content =
				SubscribedComponent(SubscriptionActions.SUBSCRIBED_BOTH, activeSubscriptions, isTrialActive, isAASubscription);
			break;
		default:
	}

	return (
		<>
			{loading && (
				<div className={styles.loader}>
					<AppLoader />
				</div>
			)}
			{!loading && content}
		</>
	);
});

const NoSubscriptionComponent = (
	{
		annualPlanName,
		monthlyPlanName,
		plans,
		subscriptionState,
		isTrialAvailable
	}: {
		annualPlanName: string,
		monthlyPlanName: string,
		plans: SubscriptionPlan[],
		subscriptionState?: SubscriptionActions,
		isTrialAvailable: boolean
	}
): ReactElement => {
	const { t } = useTranslation();
	const currentLang = useSelector(({ currentLang }) => currentLang);

	return (
		<div className={classNames(styles.container, styles.none)}>
			<div className={styles.joinBlockContainer}>
				<PlusJoinBlock type={subscriptionState ?? SubscriptionActions.NONE} isTrialAvailable={isTrialAvailable} />
			</div>
			<div className={styles.planCardsContainer}>
				<PlusCard
					plan={getPlanByCode(annualPlanName, plans)}
					planType={SubscriptionPlans.ANNUAL}
				/>
				<PlusCard
					plan={getPlanByCode(monthlyPlanName, plans)}
					planType={SubscriptionPlans.MONTHLY}
				/>
			</div>
			<div className={styles.textInfo}>
				<Trans i18nKey="SELECT_SUBSCRIPTION.PLUS_SUBSCRIPTIONS_TERMS" />
			</div>
			<div className={styles.subscriptionLinks}>
				<NavLink to="/arkadium-player-agreement.pdf" target="_blank" rel="noopener">
					<I18nText keyName="PLAYER_AGREEMENT" />
				</NavLink>{','}&nbsp;
				<NavLink to="/arkadium-plus-agreement.pdf" target="_blank" rel="noopener">
					<I18nText keyName="PLUS_SUBSCRIPTION_AGREEMENT" />
				</NavLink>&nbsp;
				{t('SELECT_SUBSCRIPTION.AND')}&nbsp;
				<NavLink to={UrlService.createPrivacyPolicyURL(currentLang, true)} target="_blank" rel="noopener">
					<I18nText keyName="FOOTER_PRIVACY_POLICY" />
				</NavLink>
			</div>
			<div className={styles.faq}>
				<PlusFAQBlock />
			</div>
		</div>
	);
};
const StandardComponent = (
	subscriptions: UserSubscription[],
	isTrialActive: boolean,
	isAASubscription: boolean
): ReactElement => (
	<div className={styles.container}>
		<div className={styles.card}>
			<PlusAvatarCardBlock
				type={SubscriptionActions.STANDARD}
				date={getFirstDateSubscription(subscriptions).startDate}
				isTrialActive={isTrialActive}
			/>
		</div>
		<div className={styles.stats}>
			<PlusStatsBlock
				type={SubscriptionActions.STANDARD}
				isTrialActive={isTrialActive}
				isAASubscription={isAASubscription}
			/>
		</div>
		<div className={styles.status}>
			<StatusBlock
				date={getLastDateSubscription(subscriptions).endDate}
				planId={getLastDateSubscription(subscriptions).planId}
				isTrialActive={isTrialActive}
			/>
		</div>
		<div className={styles.history}>
			<PlusHistoryBlock />
		</div>
		<div className={styles.faq}>
			<PlusFAQBlock />
		</div>
	</div>
);
const RenewComponent = (
	subscriptions: UserSubscription[],
	isTrialActive: boolean,
	isAASubscription: boolean
): ReactElement => (
	<div className={styles.container}>
		<div className={styles.card}>
			<PlusAvatarCardBlock
				type={SubscriptionActions.RENEW}
				date={getLastDateSubscription(subscriptions).endDate}
				isTrialActive={isTrialActive}
			/>
		</div>
		<div className={styles.stats}>
			<PlusStatsBlock
				type={SubscriptionActions.RENEW}
				isTrialActive={isTrialActive}
				isAASubscription={isAASubscription}
			/>
		</div>
		<div className={styles.status}>
			<StatusBlock
				date={getLastDateSubscription(subscriptions).endDate}
				planId={getLastDateSubscription(subscriptions).planId}
				isTrialActive={isTrialActive}
			/>
		</div>
		<div className={styles.history}>
			<PlusHistoryBlock />
		</div>
		<div className={styles.faq}>
			<PlusFAQBlock />
		</div>
	</div>
);
const SubscribedComponent = (
	type: SubscriptionActions,
	subscriptions: UserSubscription[],
	isTrialActive: boolean,
	isAASubscription: boolean
): ReactElement => (
	<div className={styles.container}>
		<div className={styles.card}>
			<PlusAvatarCardBlock
				type={type}
				date={getFirstDateSubscription(subscriptions).startDate}
				isTrialActive={isTrialActive}
			/>
		</div>
		<div className={styles.stats}>
			<PlusStatsBlock type={type} isTrialActive={isTrialActive} isAASubscription={isAASubscription} />
		</div>
		<div>
			<AlreadySubscribedBlock type={type} />
		</div>
		<div className={styles.faq}>
			<PlusFAQBlock />
		</div>
	</div>
);
