import { environment } from '../client/config/environment';
import { EnvironmentName } from '../client/constants/Environment';

// This value should be set to true only if you're developing on your server.
// dev3 is added for testing prod env
const isDevelopmentMode = environment.Name !== EnvironmentName.PRODUCTION
  && environment.Name !== EnvironmentName.STAGING
  && environment.Name !== EnvironmentName.CANARY;
const minLPCallsInterval = isDevelopmentMode ? 150 : 10;
let deferCallTimeout: number | undefined = 0;

export const deferCall = (callback: () => void, timeout = minLPCallsInterval) => {
  deferCallTimeout = deferCallTimeout !== undefined ? deferCallTimeout + timeout : timeout;

  (function (t, cb) {
    setTimeout(() => {
      cb();
      deferCallTimeout = undefined;
    }, t);
  })(deferCallTimeout, callback);
};
