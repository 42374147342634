import React from 'react';

import { useTranslation } from "react-i18next";

import styles from './SrOnly.css';

type SrOnlyProps = {
    label?: string;
};

export const SrOnly = React.memo(({ label }: SrOnlyProps) => {
    SrOnly.displayName = 'SrOnly';
    const { t } = useTranslation();
    const BASE_LABEL = t('LANGUAGE_SWITCHER.SR_ONLY');

    return <div className={styles.screenReaderOnly}>{label ?? BASE_LABEL}</div>;
});
