import React, { useContext, useEffect, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';


import { validationSchema } from './validationSchemas/RequestEmailSchema';
import { Input } from '../../../../atoms/HookFormInput/Input';
import { I18nText } from '../../../../atoms/i18nText/i18nText';
import { SignInUpComponents } from '../../../../constants/SignInUpComponents';
import { Button } from '../../../../FigmaStyleguide/Button/Button';
import CaptchaIframe from '../../../../molecules/Captcha/CaptchaIframe';
import useCaptcha, {
  RECAPTCHA_ACTIONS,
  RECAPTCHA_MODES
} from '../../../../molecules/Captcha/hooks/useCaptcha';
import { Divider } from '../../../../molecules/Divider/Divider';
import {
  PASSWORD_VALIDATION_INSTRUCTIONS_IDS,
  ValidationHints
} from '../../../../molecules/ValidationHints/ValidationHints';
import { Analytics } from '../../../../services/Analytics/Analytics';
import { AuthDataContext } from '../../../../services/AuthDataReact';
import { LocalStorageService } from '../../../../services/LocalStorage';
import { TranslationService } from '../../../../services/TranslationService';
import UserService, { AuthType } from '../../../../services/UserService';
import { setSignInUpState, setSnackbarData } from '../../../../store/ducks/layout';
import { setShowRecaptcha } from '../../../../store/ducks/recaptcha';
import styles from '../RightPanelCommonStyles/RightPanelCommonStyles.css';
import { RightPanelWrapper } from '../RightPanelWrapper/RightPanelWrapper';


export const RequestEmail = React.memo(() => {
  const dispatch = useDispatch();
  const authDataContext = useContext(AuthDataContext);
  const { checkBox, authProviderToken, authProvider } = authDataContext.value;
  const [loading, setLoading] = useState<boolean>(false);
  const {
    iframeRef,
    iframeURL,
    setIsIframeCaptchaLoaded,
    getCaptchaToken,
    clearCaptchaData,
    showChallengeRecaptcha,
    captchaToken
  } = useCaptcha();
  const handleSignIn = () => {
    dispatch(setSignInUpState(SignInUpComponents.SIGN_IN));
  };
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors }
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(validationSchema),
    defaultValues: {
      email: '',
      password: ''
    }
  });
  const values = watch();
  const { email, password } = values;

  useEffect(() => {
    if (captchaToken && authProvider === AuthType.Google) {
      fetchGoogleLogin().then(r => r);
    }

    if (captchaToken && authProvider === AuthType.Facebook) {
      fetchFacebookLogin().then(r => r);
    }
  }, [captchaToken]);

  const onSubmit = () => {
    setLoading(true);

    if (authProvider === AuthType.Facebook || authProvider === AuthType.Google) {
      getCaptchaToken(RECAPTCHA_ACTIONS.SIGN_IN);
    } else {
      dispatch(
        setSnackbarData({
          isOpened: true,
          message: 'No social network selected',
          type: 'error',
          parentNode: 'rightSideMenu'
        })
      );
    }
  };
  const fetchFacebookLogin = async () => {
    try {
      await UserService.loginViaFacebook(
        {
          token: authProviderToken,
          email,
          emailRetrievalConsent: checkBox,
          registrationPlaceUrl: new URL(UserService.generateUrl()),
          password: null,
          user: null,
          captchaToken,
          captchaMode: showChallengeRecaptcha ? RECAPTCHA_MODES.CHALLENGE : undefined
        }
      );
      handleOAuthSuccess(AuthType.Facebook);
    } catch (err) {
      handleOAuthError(AuthType.Facebook, err);
    }
  };
  const fetchGoogleLogin = async () => {
    try {
      await UserService.loginViaGoogle(
        {
          token: authProviderToken,
          email,
          emailRetrievalConsent: checkBox,
          registrationPlaceUrl: new URL(UserService.generateUrl()),
          password: null,
          user: null,
          captchaToken,
          captchaMode: showChallengeRecaptcha ? RECAPTCHA_MODES.CHALLENGE : undefined
        }
      );
      Analytics.trackEvent(Analytics.profile.socialEmailRequest()).then(r => r);
      handleOAuthSuccess(AuthType.Google);
    } catch (err) {
      handleOAuthError(AuthType.Google, err);
    }
  };
  const handleOAuthSuccess = (authProvider: AuthType) => {
    Analytics.trackEvent(Analytics.profile.socialEmailRequest()).then(r => r);
    LocalStorageService.setItem('authProvider', authProvider);
    authDataContext.setAuthData({ ...authDataContext.value, email, authProvider });
    clearCaptchaData();
    UserService.openAfterSignPanel();
    setLoading(false);
  };
  const handleOAuthError = (authProvider: AuthType, error: { errCode: number, errEmail: string }) => {
    const provider = authProvider === AuthType.Google ? AuthType.Google : AuthType.Facebook;

    if (error.errCode !== 1023) {
      clearCaptchaData();
    }

    if (error.errCode === 1018) {
      authDataContext.setAuthData({
        ...authDataContext.value,
        email,
        authProvider: provider
      });
      dispatch(setSignInUpState(SignInUpComponents.AFTER_SIGN));
    } else {
      dispatch(
        setSnackbarData({
          isOpened: true,
          message: UserService.errorCodeToText(error.errCode),
          type: 'error',
          parentNode: 'rightSideMenu'
        })
      );
      dispatch(setShowRecaptcha(error.errCode === 1023));
    }

    setLoading(false);
  };

  return (
    <RightPanelWrapper headerLabelKey="Complete your account">
      <I18nText
        as="div" className={styles.subHeader}
        keyName={'REGISTER.SOCIAL_COMPLETE_PROFILE_DESCRIPTION'}
      />&nbsp;
      {!showChallengeRecaptcha && (
        <form onSubmit={handleSubmit(onSubmit)} className={styles.formContent}>
          <div className={styles.inputRow}>
            <Input
              required
              className={styles.input}
              isValid={!errors.email}
              ariaInvalid={errors.email ? 'true' : 'false'}
              register={register}
              name="email"
              type="text"
              maxLength={50}
              placeholder={TranslationService.translateIt('REGISTER.ENTER_YOUR_EMAIL_ADDRESS')}
              value={email}
              showError={errors?.email}
              errorMessage={errors?.email?.message ?? ''}
            />
          </div>
          <div className={styles.inputRow}>
            <Input
              className={styles.input}
              isValid={!errors.password}
              ariaInvalid={errors.password ? 'true' : 'false'}
              register={register}
              name="password"
              type="password"
              minLength={3}
              maxLength={50}
              placeholder={TranslationService.translateIt('REGISTER.ENTER_YOUR_PASSWORD_OPTIONAL')}
              value={password}
              showError={errors?.password}
              errorMessage={errors?.password?.message ?? ''}
              ariaDescribedBy={PASSWORD_VALIDATION_INSTRUCTIONS_IDS}
            />
            <ValidationHints password={password}/>
          </div>
          <div className={styles.buttonRow}>
            <Button
              className={styles.submitButton}
              type="submit"
              disabled={!!errors.email || loading}
              loading={loading}>
              <I18nText keyName={'REGISTER.SUBMIT'}/>
            </Button>
          </div>
          <Divider className={styles.rememberRow}/>
          <Button isLink onClick={handleSignIn}>
            <I18nText keyName={'Start over'}/>&nbsp;
          </Button>
        </form>
      )}
      <CaptchaIframe iframeRef={iframeRef} iframeURL={iframeURL} setIsIframeCaptchaLoaded={setIsIframeCaptchaLoaded}/>
    </RightPanelWrapper>
  );
});
