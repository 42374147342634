import React from 'react';

import classNames from 'classnames';
import * as yup from 'yup';

import { I18nText } from '../../atoms/i18nText/i18nText';
import { CheckmarkIcon } from '../../atoms/Icons/Styleguide/CheckmarkIcon';
import { CrossIcon } from '../../atoms/Icons/Styleguide/CrossIcon';
import styles from './ValidationHints.css';

type Props = {
    className?: string;
    password?: string;
};

export const PASSWORD_VALIDATION_INSTRUCTIONS_IDS = 'password-length-criteria password-register-criteria';

export const ValidationHints = React.memo(({ className, password }: Props) => {
    const passwordMinValidation = yup.string().min(8);
    const passwordUppercaseValidation = yup.string().matches(/(.*[A-Z].*)/);
    const isPasswordEmpty = password.length === 0;
    const passwordMinLength = '8';
    const isLengthOk = passwordMinValidation.isValidSync(password);
    const isRegisterOk = passwordUppercaseValidation.isValidSync(password);
    return (
        <div className={classNames(styles.root, className)}>
            <div
                className={classNames(styles.validationMessage, {
                    [styles.validationMessageError]: !isPasswordEmpty && !isLengthOk,
                    [styles.validationMessageOk]: !isPasswordEmpty && isLengthOk,
                })}
                id="password-length-criteria"
                aria-atomic="true"
                aria-live="polite"
            >
                <div className={styles.mark}>
                    {(isPasswordEmpty || !isLengthOk) && <CrossIcon />}
                    {!isPasswordEmpty && isLengthOk && <CheckmarkIcon />}
                </div>
                <div>
                    <I18nText keyName={'REGISTER.AT_LEAST'} />
                    <span> {passwordMinLength} </span>
                    <I18nText keyName={'REGISTER.CHARS'} />
                </div>
                <div className={styles.screenReaderOnly}>
                    {isLengthOk ? 'Password length criteria is met' : 'Password length criteria is not met'}
                </div>
            </div>
            <div
                className={classNames(styles.validationMessage, {
                    [styles.validationMessageError]: !isPasswordEmpty && !isRegisterOk,
                    [styles.validationMessageOk]: !isPasswordEmpty && isRegisterOk,
                })}
                id="password-register-criteria"
                aria-atomic="true"
                aria-live="polite"
            >
                <div className={styles.mark}>
                    <div>
                        {(isPasswordEmpty || !isRegisterOk) && <CrossIcon />}
                        {!isPasswordEmpty && isRegisterOk && <CheckmarkIcon />}
                    </div>
                </div>
                <I18nText keyName={'REGISTER.UPPERCASE_LETTER'} />
                <div className={styles.screenReaderOnly}>
                    {isRegisterOk ? 'Password register criteria is met' : 'Password register criteria is not met'}
                </div>
            </div>
        </div>
    );
});
