import { environment } from "../config/environment";

export const prefixGems = environment?.RECURLY_GEMS_PREFIX;
const getSuffixGemsAdvantage = (plusFeatureFlag: boolean) => plusFeatureFlag ? '_advantage_2025' : '_advantage';

export const RecurlyGemsItemNames = ['5000_gem', '2400_gem', '1120_gem', '600_gem', '350_gem', '90_gem'];
export const RecurlyPlusGemsItemNames = ['5000_gem_advantage_2025', '2400_gem_advantage_2025', '1120_gem_advantage_2025', '600_gem_advantage_2025', '350_gem_advantage_2025', '90_gem_advantage_2025'];
export const gemsSkuList = RecurlyGemsItemNames.map((itemName) => `${prefixGems}${itemName}`);
export const plusGemsSkuList = RecurlyPlusGemsItemNames.map((itemName) => `${prefixGems}${itemName}`);

export enum RecurlyGemsRibbonLabels {
    BEST_VALUE = 'Best Value',
    MOST_POPULAR = 'Most Popular',
}

export const RecurlyGemPackItems = (plusFeatureFlag: boolean) => {
    const subscribersGemPackItems = {};
    const registeredGemPackItems = {};

    RecurlyGemsItemNames.forEach((gemPackName) => generateGemPackItem(subscribersGemPackItems, true, gemPackName, plusFeatureFlag));
    RecurlyGemsItemNames.forEach((gemPackName) => generateGemPackItem(registeredGemPackItems, false, gemPackName, plusFeatureFlag));
    return {
        forSubscribers: subscribersGemPackItems,
        forRegistered: registeredGemPackItems,
    };
};

function generateGemPackItem(linkToGemPackObj, isForSubscribers, gemPackName, plusFeatureFlag): void {
    const packName = generatePackName(gemPackName); // used for texts on purchase page and as keys of chosen pack in store
    const gemsNumber = packName.split(' ')[0];

    linkToGemPackObj[generateCurrentPackName(gemPackName)] = {
        key: packName,
        plan: `${prefixGems}${gemPackName}${isForSubscribers ? getSuffixGemsAdvantage(plusFeatureFlag) : ''}`,
        title: packName, // reversed title and description texts as for design idea
        description: 'Gems', // used in purchase summary block
        gemsAmount: gemsNumber,
    };
}

export function generateCurrentPackName(gemPackName: string): string {
    return `${prefixGems}${gemPackName}`;
}

export function generatePackName(gemPackName: string): string {
    return gemPackName
        .replace(new RegExp(`^${prefixGems}`, 'gi'), '')
        .replace('_', ' ')
        .replace('g', 'G');
}
