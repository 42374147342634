import React, { useEffect, useState } from 'react';

import classnames from 'classnames';
import { push } from 'connected-react-router';
import { batch, useDispatch } from 'react-redux';

import styles from './GemsTabAdvantagePromo.css';
import { Responsive } from '../../atoms/Layout/Responsive';
import { ArkCssBreakpoints } from "../../constants/ArkCssBreakpoints";
import { SubscriptionSource } from '../../models/Subscription/SubscriptionData';
import { Analytics } from '../../services/Analytics/Analytics';
import { TranslationService } from '../../services/TranslationService';
import UserService from '../../services/UserService';
import { setSubscriptionSource } from '../../store/ducks/subscription/common';
import { Button } from '../Button/Button';

type Text = {
    title: string;
    description: string;
};

type GemsAdvantagePromoRenderListProps = {
    textsList: Text[];
};

type GemsTabAdvantagePromoProps = {
    storyIsForSubscriber?: boolean;
    storyTextsList?: Text[];
    storySubscribeButtonText?: string;
    storySubscribeButtonTextNow?: string;
    storySubscriberText?: string;
    storySubscriberTextDesktop?: string;
};

export const TEXTS_LIST: Text[] = [
    {
        title: TranslationService.translateIt('GEMS_TAB_ADVANTAGE_PROMO.GEMS_DISCOUNT_TITLE'),
        description: TranslationService.translateIt('GEMS_TAB_ADVANTAGE_PROMO.GEMS_DISCOUNT_DESCRIPTION')
    },
    {
        title: TranslationService.translateIt('GEMS_TAB_ADVANTAGE_PROMO.AD_FREE_GAMES_TITLE'),
        description: TranslationService.translateIt('GEMS_TAB_ADVANTAGE_PROMO.AD_FREE_GAMES_DESCRIPTION'),
    },
    {
        title: TranslationService.translateIt('GEMS_TAB_ADVANTAGE_PROMO.EARLY_ACCESS_TITLE'),
        description: TranslationService.translateIt('GEMS_TAB_ADVANTAGE_PROMO.EARLY_ACCESS_DESCRIPTION'),
    },
];
const SUBSCRIBER_TEXT = TranslationService.translateIt('GEMS_TAB_ADVANTAGE_PROMO.SUBSCRIBER_TEXT');
const SUBSCRIBER_TEXT_DESKTOP = TranslationService.translateIt('GEMS_TAB_ADVANTAGE_PROMO.SUBSCRIBER_TEXT_DESKTOP');
const GemsAdvantagePromoRenderLogo = () => (
    <img className={styles.logo} alt="Arkadium Advantage logo" src="/images/gems/ArkadiumAdvantagePromoLogo.svg" />
);
const GemsAdvantagePromoSubscriberText = ({ subscriberText, subscriberTextDesktop }) => (
    <>
        <Responsive minWidth={ArkCssBreakpoints.ARK_SMALL_DESKTOP}>
            <div className={styles.subscriberText}>{subscriberTextDesktop}</div>
        </Responsive>
        <Responsive maxWidth={ArkCssBreakpoints.ARK_SMALL_DESKTOP_BELOW}>
            <div className={styles.subscriberText}>{subscriberText}</div>
        </Responsive>
    </>
);
const GemsAdvantagePromoRenderList = ({ textsList }: GemsAdvantagePromoRenderListProps) => (
    <ul className={styles.list}>
        {textsList &&
            textsList.length &&
            textsList.map(
                (texts, index) =>
                    texts &&
                    texts.title &&
                    texts.description && (
                        <li className={styles.list_item} key={index}>
                            <span className={styles.list_item_title}>{texts.title}</span>
                            &nbsp;
                            {texts.description}
                        </li>
                    )
            )}
    </ul>
);
const GemsAdvantagePromoRenderButton = ({ handleSubscribe, subscribeButtonText, subscribeButtonTextDesktop }) => (
    <>
        <Responsive maxWidth={ArkCssBreakpoints.ARK_SMALL_DESKTOP_BELOW}>
            <Button className={styles.button} advantageBlue={true} onClick={handleSubscribe}>
                {subscribeButtonText}
            </Button>
        </Responsive>
        <Responsive minWidth={ArkCssBreakpoints.ARK_SMALL_DESKTOP}>
            <Button className={styles.button} advantageBlue={true} onClick={handleSubscribe}>
                {subscribeButtonTextDesktop}
            </Button>
        </Responsive>
    </>
);

export const GemsTabAdvantagePromo = ({
    storyIsForSubscriber,
    storyTextsList,
    storySubscribeButtonText,
    storySubscribeButtonTextNow,
    storySubscriberText,
    storySubscriberTextDesktop,
}: GemsTabAdvantagePromoProps) => {
    const textsList = storyTextsList || TEXTS_LIST;
    const [isForSubscriber, setIsSubscriber] = useState(storyIsForSubscriber || false);
    const subscribeButtonText = storySubscribeButtonTextNow || TranslationService.translateIt('GEMS_TAB_ADVANTAGE_PROMO.SUBSCRIBE_NOW');
    const subscribeButtonTextDesktop = storySubscribeButtonText || TranslationService.translateIt('GEMS_TAB_ADVANTAGE_PROMO.SUBSCRIBE');
    const getSubscriberText = (text) => text.split('\n').map((text, index) => <p key={index}>{text}</p>);
    const subscriberText = getSubscriberText(storySubscriberText || SUBSCRIBER_TEXT);
    const subscriberTextDesktop = getSubscriberText(storySubscriberTextDesktop || SUBSCRIBER_TEXT_DESKTOP);
    const user = UserService.getUserFromStore();
    const isUserSubscriber = UserService.isUserSubscriber();
    const dispatch = useDispatch();
    const handleSubscribe = async () => {
        batch(() => {
            dispatch(setSubscriptionSource(SubscriptionSource.SHOP_AA_BANNER));
            dispatch(push('/subscription'));
        });
        await Analytics.trackEvent(Analytics.gems.gemShopSubscribeBanner());
    };

    useEffect(() => {
        setIsSubscriber(storyIsForSubscriber || isUserSubscriber);
    }, [user, storyIsForSubscriber, isUserSubscriber]);

    return (
        <div className={classnames(styles.container, { [styles.__forSubscribers]: isForSubscriber })}>
            <GemsAdvantagePromoRenderLogo />
            {isForSubscriber && <GemsAdvantagePromoSubscriberText {...{ subscriberText, subscriberTextDesktop }} />}
            {!isForSubscriber && <GemsAdvantagePromoRenderList {...{ isForSubscriber, textsList }} />}
            {!isForSubscriber && (
                <GemsAdvantagePromoRenderButton
                    {...{ handleSubscribe, subscribeButtonText, subscribeButtonTextDesktop }}
                />
            )}
        </div>
    );
};
