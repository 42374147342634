import React, { useContext, useEffect, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import { emailValidation, validationSchema } from './validationSchemas/ResetPasswordSchema';
import { Input } from '../../../../atoms/HookFormInput/Input';
import { I18nText } from '../../../../atoms/i18nText/i18nText';
import { SignInUpComponents } from '../../../../constants/SignInUpComponents';
import { Button } from '../../../../FigmaStyleguide/Button/Button';
import CaptchaIframe from '../../../../molecules/Captcha/CaptchaIframe';
import useCaptcha, {
  RECAPTCHA_ACTIONS,
  RECAPTCHA_MODES
} from '../../../../molecules/Captcha/hooks/useCaptcha';
import { Analytics } from '../../../../services/Analytics/Analytics';
import { AuthDataContext } from '../../../../services/AuthDataReact';
import { TranslationService } from '../../../../services/TranslationService';
import UserService from '../../../../services/UserService';
import { setSignInUpState, setSnackbarData } from '../../../../store/ducks/layout';
import { setShowRecaptcha } from '../../../../store/ducks/recaptcha';
import styles from '../RightPanelCommonStyles/RightPanelCommonStyles.css';
import { RightPanelWrapper } from '../RightPanelWrapper/RightPanelWrapper';


export const ResetPassword = React.memo(() => {
  const dispatch = useDispatch();
  const inputElement = React.useRef<HTMLInputElement | null>(null);
  const authDataContext = useContext(AuthDataContext);
  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<string>(TranslationService.translateIt('REGISTER.REMEMBER_YOUR_PASSWORD'));
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors }
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(validationSchema),
    defaultValues: {
      email: authDataContext.value.email || ''
    }
  });
  const values = watch();
  const { email } = values;
  const {
    iframeRef,
    iframeURL,
    setIsIframeCaptchaLoaded,
    getCaptchaToken,
    clearCaptchaData,
    showChallengeRecaptcha,
    captchaToken
  } = useCaptcha();

  useEffect(() => {
    const keyDownHandler = (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        event.preventDefault();
        // call submit
        inputElement.current?.click();
      }
    };

    document.addEventListener('keydown', keyDownHandler);

    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, []);
  useEffect(() => {
    if (captchaToken) {
      fetchRequestResetPassword().then(r => r);
    }
  }, [captchaToken]);

  const handleSignIn = () => {
    dispatch(setSignInUpState(SignInUpComponents.SIGN_IN));
  };
  const fetchRequestResetPassword = async () => {
    const url = new URL(UserService.generateUrl());

    try {
      await UserService.requestResetPassword({
        email,
        resetPasswordConfirmationFormUrl: url,
        registrationPlaceUrl: url,
        captchaToken,
        captchaMode: showChallengeRecaptcha ? RECAPTCHA_MODES.CHALLENGE : undefined
      });
      handleRequestResetPasswordSuccess();
    } catch (err) {
      handleRequestResetPasswordError(err);
    } finally {
      setLoading(false);
    }
  };
  const handleRequestResetPasswordSuccess = () => {
    clearCaptchaData();
    dispatch(setSignInUpState(SignInUpComponents.THANK_YOU));
    void Analytics.trackEvent(Analytics.profile.resetPassword('start'));
  };
  const handleRequestResetPasswordError = (err: number) => {
    dispatch(setShowRecaptcha(err === 1023));

    if (err !== 1023) {
      clearCaptchaData();
    }

    if (err === 1018 || err === 1019) {
      setMessage(TranslationService.translateIt('PLEASE_CHECK_YOUR_INBOX_FOR_THE_CONFIRMATION'));
      dispatch(
        setSnackbarData({
          isOpened: true,
          message: TranslationService.translateIt('YOUR_EMAIL_ADDRESS_HAS_NOT_YET_BEEN_CONFIRMED'),
          type: 'error',
          parentNode: 'rightSideMenu'
        })
      );
    } else {
      dispatch(
        setSnackbarData({
          isOpened: true,
          message: UserService.errorCodeToText(err),
          type: 'error',
          parentNode: 'rightSideMenu'
        })
      );
    }
  };
  const onSubmit = () => {
    setLoading(true);
    authDataContext.setAuthData({ ...authDataContext.value, email });
    getCaptchaToken(RECAPTCHA_ACTIONS.PASSWORD_RESET);
  };

  return (
    <RightPanelWrapper headerLabelKey="REGISTER.RESET_YOUR_PASSWORD">
      <I18nText as="div" className={styles.subHeader} keyName={'REGISTER.PLEASE_ENTER_THE_EMAIL'}/>&nbsp;
      {!showChallengeRecaptcha && (
        <form onSubmit={handleSubmit(onSubmit)} className={styles.formContent}>
          <div className={styles.inputRow}>
            <Input
              required
              className={styles.input}
              isValid={!errors.email}
              ariaInvalid={errors.email ? 'true' : 'false'}
              register={register}
              name="email"
              type="text"
              maxLength={50}
              placeholder={TranslationService.translateIt('REGISTER.ENTER_YOUR_EMAIL_ADDRESS')}
              value={values.email}
              showError={errors?.email}
              errorMessage={errors?.email?.message ?? ''}
              onBlur={() => {
                emailValidation.isValidSync(values.email);
              }}
            />
          </div>
          <div className={styles.rememberRow}>
            {message}&nbsp;
            <Button isLink onClick={handleSignIn}>
              <I18nText keyName={'REGISTER.SIGN_IN'}/>
            </Button>
          </div>
          <div className={styles.buttonRow}>
            <Button
              loading={loading}
              className={styles.submitButton}
              type="submit"
              disabled={loading}
              ref={inputElement}
            >
              <I18nText keyName={'REGISTER.SUBMIT'}/>
            </Button>
          </div>
        </form>
      )}
      <CaptchaIframe iframeRef={iframeRef} iframeURL={iframeURL} setIsIframeCaptchaLoaded={setIsIframeCaptchaLoaded}/>
    </RightPanelWrapper>
  );
});
