
import { globalErrorHandler } from '../../../../utils/LogUtils';
import { environment } from '../../../config/environment';

type Variable = string | number | boolean;

type GroupedVariables = Record<string, Record<string, Variable>>;

const CT_VARS_LOCAL_STORAGE_KEY = 'WZRK_PE';

export enum ClevertapEvents {
  FunnelPlayNow = 'funnelPlayNow',
  FunnelEndButtonPlayAgain = 'funnelEndButtonPlayAgain',
  FunnelEndButtonAllGames = 'funnelEndButtonAllGames'
}

export type ClevertapBaseProperties = {
  domain: string;
	url: string;
	page: string;
	isSubscriber?: boolean;
	isLoggedIn?: boolean;
}

export type ClevertapEventsProperties = {
  event: ClevertapEvents,
  properties: ClevertapBaseProperties & {
    gameName: string,
  }
}

export class CleverTapService {
  static groupedVariables: GroupedVariables = {};
  static variables: Record<string, Variable> = {};

  static init() {
    window.clevertap.init(environment.CT_ACCOUNT_ID, environment.CT_REGION);
    CleverTapService.parseVarsGroupsToObjects();

    return window.clevertap;
  }

  static onFetchVariables (callback: () => void) {
    window.clevertap.fetchVariables(() => {
      CleverTapService.parseVarsGroupsToObjects();
      callback();
    })
  }

  static parseVarsGroupsToObjects() {
    try {
      const varsString = decodeURIComponent(localStorage.getItem(CT_VARS_LOCAL_STORAGE_KEY));

      this.variables = JSON.parse(varsString);

      for (const varKey in this.variables) {
        if (varKey.includes('.')) {
          const [group, key] = varKey.split('.');

          this.groupedVariables[group] = this.groupedVariables[group] ? { ...this.groupedVariables[group], [key]: this.variables[varKey] } : {};
        }
      }

    } catch (error) {
      globalErrorHandler({ error, filename: 'ClevertapService.parseVarsGroupsToObjects', info: 'CT variables parsing issue' });
    }
  }

  static trackEvent({ event, properties }: ClevertapEventsProperties) {
    window.clevertap.event.push(event, properties);
  }
}
