import React, { useContext, useEffect, useRef, useState } from 'react';

import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import { I18nText } from '../../../../atoms/i18nText/i18nText';
import { ProfileRightSideRoutes } from '../../../../constants/Pages';
import { SignInUpComponents } from '../../../../constants/SignInUpComponents';
import { Button } from '../../../../FigmaStyleguide/Button/Button';
import CaptchaIframe from '../../../../molecules/Captcha/CaptchaIframe';
import useCaptcha, {
  RECAPTCHA_ACTIONS,
  RECAPTCHA_MODES
} from '../../../../molecules/Captcha/hooks/useCaptcha';
import { Analytics } from '../../../../services/Analytics/Analytics';
import { AuthDataContext } from '../../../../services/AuthDataReact';
import { TranslationService } from '../../../../services/TranslationService';
import UserService, { AuthType } from '../../../../services/UserService';
import { setProfilePanelState, setSnackbarData } from '../../../../store/ducks/layout';
import { setShowRecaptcha } from '../../../../store/ducks/recaptcha';
import styles from '../RightPanelCommonStyles/RightPanelCommonStyles.css';
import { RightPanelWrapper } from '../RightPanelWrapper/RightPanelWrapper';
import { StillNeedHelp } from '../StillNeedHelp/StillNeedHelp';

const INITIAL_RESEND_TIMER = 10; // 10 seconds

export const AfterSignUp = React.memo(() => {
  const dispatch = useDispatch();
  const authDataContext = useContext(AuthDataContext);
  const { email, authProvider } = authDataContext.value;
  const intervalRef = useRef<NodeJS.Timeout>(); // Add a ref to store the interval id
  const [timeLeft, setTimeLeft] = useState<number>(INITIAL_RESEND_TIMER);
  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState(TranslationService.translateIt('REGISTER.IF_YOU_DO_NOT_RECEIVE_EMAIL_2'));
  const activeSignInUpState = useSelector(({ signInUpState }) => signInUpState.activeComponent);
  const {
    iframeRef,
    iframeURL,
    setIsIframeCaptchaLoaded,
    getCaptchaToken,
    clearCaptchaData,
    showChallengeRecaptcha,
    captchaToken
  } = useCaptcha();

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      setTimeLeft((t) => t - 1);
    }, 1000);

    if (timeLeft <= 0) {
      clearInterval(intervalRef.current);
    }

    return () => clearInterval(intervalRef.current);
  }, [timeLeft]);

  useEffect(() => {
    if (captchaToken) {
      void fetchResendConfirmationEmail();
    }
  }, [captchaToken]);

  const fetchResendConfirmationEmail = async () => {
    try {
      await UserService.resendConfirmationEmail({
        email,
        authType: authProvider ?? AuthType.EmailPassword,
        captchaToken,
        captchaMode: showChallengeRecaptcha ? RECAPTCHA_MODES.CHALLENGE : undefined
      });
      handleResendConfirmationSuccess();
    } catch (err) {
      handleResendConfirmationError(err);
    }
  };
  const handleResendConfirmationSuccess = () => {
    setMessage(TranslationService.translateIt('REGISTER.THE_EMAIL_HAS_BEEN_SENT_AGAIN'));
    void Analytics.trackEvent(Analytics.profile.resendConfirmation());
    clearCaptchaData();
    setLoading(false);
    setTimeLeft(INITIAL_RESEND_TIMER);
  };
  const handleResendConfirmationError = (err: number) => {
    dispatch(setShowRecaptcha(err === 1023));
    setLoading(false);
    setTimeLeft(INITIAL_RESEND_TIMER);
    dispatch(
      setSnackbarData({
        isOpened: true,
        message: UserService.errorCodeToText(err),
        type: 'error'
      })
    );
  };
  const onResendClick = () => {
    setLoading(true);
    getCaptchaToken(RECAPTCHA_ACTIONS.CONFIRMATION_RESEND);
  };
  const GetHeaderActions = () => {
    return (
      <div className={classNames(styles.newVersion)}>
        <Button loading={loading} onClick={onResendClick} disabled={Boolean(timeLeft)}>
          {timeLeft ? String(timeLeft) : <I18nText keyName={'REGISTER.RESEND_TXT'} />}
        </Button>
      </div>
    );
  };
  const getBodyMiddle = () => {
    switch (activeSignInUpState) {
      case SignInUpComponents.AFTER_SIGN:
        return <GetHeaderActions />;

      case SignInUpComponents.AFTER_SIGN_COMPLETE:
        return null;

      default:
        break;
    }
  };
  const sendCorrect = () => {
    dispatch(
      setProfilePanelState({
        isOpened: true,
        caption: 'FOR_YOUR_SECURITY',
        target: ProfileRightSideRoutes.REQUEST_EMAIL
      })
    );
  };
  const getButtonsResend = () => {
    switch (activeSignInUpState) {
      case SignInUpComponents.AFTER_SIGN:
        return null;

      case SignInUpComponents.AFTER_SIGN_COMPLETE:
        return <GetHeaderActions />;

      default:
        break;
    }
  };
  const getNoticeEmailLabel = () => {
    switch (activeSignInUpState) {
      case SignInUpComponents.AFTER_SIGN:
        return null;

      case SignInUpComponents.AFTER_SIGN_COMPLETE:
        return (
          <div className={styles.noticeText}>
            <I18nText as="div" keyName={'REGISTER.NOTICE_A_TYPO'} />
            <Button isLink onClick={sendCorrect}>
              <I18nText keyName={'REGISTER.CLICK_HERE'} />
            </Button>&nbsp;
            <I18nText keyName={'REGISTER.TO_SEND_IT'} />
          </div>
        );
      default:
        break;
    }
  };

  return (
    <RightPanelWrapper headerLabelKey="REGISTER.CONGRATULATIONS_YOURE_ALMOST_DONE">
      <div className={styles.subHeader}>
        <I18nText keyName={'REGISTER.TO_COMPLETE_YOUR_PROFILE'} />&nbsp;
        <span className={styles.subHeaderEmail}>{email || 'longemailaddress@email.com'}</span>
      </div>
      {!showChallengeRecaptcha && (
        <>
          <div className={styles.receiveText}>{message}</div>
          {getBodyMiddle()}
          {getNoticeEmailLabel()}
          {getButtonsResend()}
          <StillNeedHelp />
        </>
      )}
      <CaptchaIframe iframeRef={iframeRef} iframeURL={iframeURL} setIsIframeCaptchaLoaded={setIsIframeCaptchaLoaded} />
    </RightPanelWrapper>
  );
});
AfterSignUp.displayName = 'AfterSignUp';
