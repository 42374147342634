import { createAction, createReducer } from 'redux-act';

import { environment } from '../../../config/environment';
import { EnvironmentName } from '../../../constants/Environment';

export type arkConfigType = {
  textCaptchaOn1StepPurchase: string;
  lpDelayWakeUp: number;
  feedId: string,
  disablePreroll: {
    starDate: string;
    endDate: string;
    env: string[];
    days: number;
  };
  plusFeature: {
    isEnabled: boolean;
    env: string[]
  }
};

export const arkConfigReducer = createReducer<arkConfigType>({}, null);
export const setArkConfigData = createAction<arkConfigType>('set ark config');
arkConfigReducer.on(setArkConfigData, (_state, payload) => {
  const currentEnv = EnvironmentName[environment.Name]?.toLowerCase();

  if (payload?.plusFeature) {
    const { isEnabled, env } = payload.plusFeature;

    payload = {
      ...payload,
      plusFeature: {
        ...payload.plusFeature,
        isEnabled: isEnabled && env?.includes(currentEnv)
      }
    };
  }

  return payload;
});
