import React, { FC, useEffect, useState } from 'react';

import classNames from 'classnames';
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import styles from './AnnualCard.css';
import { Button } from '../../FigmaStyleguide/Button/Button';
import { SubscriptionStar } from '../../FigmaStyleguide/SubscriptionStar/SubscriptionStar';
import { SubscriptionPlan } from '../../models/Subscription/SubscriptionData';
import { ManageType } from '../../organisms/HeaderSideMenu/SubscriptionTab/ManagePlan/ManagePlan';

type AnnualCardProps = {
  btnLabel: string;
  managePlanStyles?: boolean;
  manageType?: ManageType;
  plan: SubscriptionPlan;
  handlerBtn: () => void;
  monthlyPlan?: SubscriptionPlan;
};
export const AnnualCard: FC<AnnualCardProps> = React.memo(
  ({ btnLabel, managePlanStyles, manageType, plan, handlerBtn, monthlyPlan }: AnnualCardProps) => {
    AnnualCard.displayName = 'AnnualCard';

    const { t } = useTranslation();
    const plusFeatureFlag = useSelector(({ arkConfig }) => arkConfig?.plusFeature?.isEnabled);
    const [planPriceLabel, setPlanPriceLabel] = useState<number>(null);
    const [planPriceFull, setPlanPriceFull] = useState<number>(null);
    const [currencySign, setCurrencySign] = useState<string>('');
    const [percentsSaved, setPercentsSaved] = useState<number | null>(null);

    useEffect(() => {
      const planFullPrice = plan?.currencies[0]?.unitAmount;
      const planPriceValue = planFullPrice / plan?.intervalLength;
      const planLabel = Math.floor(planPriceValue * 100) / 100;
      const planCurency = plan?.currencies[0]?.currency === 'USD' ? '$' : ''; // create a helper for getting currencies signs
      const monthlySubscrPayment = monthlyPlan ? monthlyPlan?.currencies[0]?.unitAmount : null;
      const savedSum = calculatePercentsSaved(monthlySubscrPayment, planPriceValue);

      setPercentsSaved(savedSum);
      setPlanPriceFull(planFullPrice);
      setPlanPriceLabel(planLabel);
      setCurrencySign(planCurency);
    }, []);

    const calculatePercentsSaved = (monthlySubscrPayment: number, planPriceValue: number): number|null => {
      if (plusFeatureFlag) {
        return (
          monthlySubscrPayment && monthlySubscrPayment > planPriceValue
            ? Math.ceil(((monthlySubscrPayment - planPriceValue) / monthlySubscrPayment) * 100)
            : null
        );
      }

      return (
        monthlySubscrPayment && monthlySubscrPayment > planPriceValue
          ? Math.floor(((monthlySubscrPayment - planPriceValue) / monthlySubscrPayment) * 100)
          : null
      );
    };

    return (
      <div
        className={classNames(
          styles.container,
          managePlanStyles && styles.managePlanStyles,
          manageType === ManageType.UPDATE && styles.managePlanUpdate
        )}
      >
        <div className={styles.info}>
          <div>
            <div className={styles.heading}>{t('PAYMENT_CARD.ANNUAL')}</div>
            <div>
              <span className={styles.price}>
                {currencySign}
                {planPriceLabel}
              </span>
              &nbsp;
              <span className={styles.monthLabel}>{t('PAYMENT_CARD.MONTH_PERIOD')}</span>
            </div>
          </div>
        </div>
        {manageType !== ManageType.UPDATE && (
          <div className={styles.btnRow}>
            <Button className={styles.btnSubscribe} onClick={handlerBtn}>
              {btnLabel}
            </Button>
          </div>
        )}

        <div className={styles.savingsInfo}>
          <div>
            {t('PAYMENT_CARD.BILLED_AT')}
            &nbsp;
            <strong>
              {currencySign}
              {planPriceFull}
            </strong>
            {t('PAYMENT_CARD.YEAR_PERIOD')}
            &nbsp;
            {!managePlanStyles && <br/>}
            {t('PAYMENT_CARD.THAT')}
            &nbsp;
            <strong>{percentsSaved ? `${percentsSaved}%` : '37%'}</strong>
            &nbsp;
            {t('PAYMENT_CARD.SAVINGS')}
          </div>
        </div>
        <div className={styles.saveStar}>
          <SubscriptionStar percentsSaved={percentsSaved}/>
        </div>
      </div>
    );
  }
);
