import React from 'react';

import { iconProps } from '../../models/Icon/IconPropsModel';

export const PlusSubscriptionTabIcon = React.memo((props: iconProps) => {
  return (
    <svg
      className={props.className}
      width="24"
      height="28"
      viewBox="0 0 24 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.027 0.518077C20.064 0.0419648 19.1501 0.000477066 18.8843 0C16.9652 0.000681523 15.5725 1.16483 14.2234 2.28983L14.1925 2.316L2.0783 12.2033C0.986039 13.0983 -0.204151 14.8413 0.0297066 16.8094C0.132671 17.673 0.608151 19.2694 2.64394 20.2404L2.67221 20.2535L2.70047 20.2665C3.29674 20.5335 3.93171 20.667 4.58183 20.667H4.66662C5.56876 20.6617 6.39149 20.4445 7.05473 20.2692C7.26032 20.2142 7.45313 20.1645 7.58168 20.1383L7.615 20.1305L7.64832 20.1226C8.29371 19.9761 8.95661 19.8923 9.61716 19.8792H9.64811C10.3524 19.8792 11.0594 20.0415 11.694 20.3529C13.185 21.0831 13.8765 22.4388 14.3826 23.428L14.4186 23.4986C14.4752 23.6086 14.56 23.8127 14.6451 24.0116C15.0869 25.0663 15.8248 26.8249 17.5676 27.5656C18.187 27.8377 18.8449 27.9817 19.5186 28H19.5801C22.0991 28 23.996 25.7729 23.996 22.8208V6.85139C24.0192 4.79958 24.0424 1.99932 21.027 0.518077ZM21.4308 6.83568V22.8182C21.4308 23.7917 20.9887 25.3803 19.5852 25.3803C19.2355 25.3724 18.8913 25.2939 18.5723 25.1526C17.547 24.7287 17.1382 23.1401 16.7038 22.2895C16.1358 21.1772 15.1229 19.1281 12.8098 17.9923C11.8225 17.5134 10.7457 17.2647 9.65315 17.2647H9.59157C8.751 17.2804 7.91313 17.3825 7.09074 17.5709C6.42212 17.7044 5.48397 18.0499 4.63835 18.0499H4.58452C4.29144 18.0499 4.00104 17.987 3.73353 17.8693C1.66206 16.8826 2.81624 14.9591 3.68473 14.2446L15.8093 4.34948C16.9814 3.37347 17.8731 2.61724 18.8856 2.61698C18.8967 2.61703 19.4008 2.61981 19.9143 2.87342C21.4023 3.60357 21.4537 4.78392 21.4308 6.83568Z"
        fill="#231F20" />
    </svg>

  );
});
