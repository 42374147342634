import React, { FC } from 'react';

import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import styles from './HoverElement.css';

interface HoverElementProps {
  visible: boolean;
}

export const HoverElement: FC<HoverElementProps> = React.memo(({ visible }: HoverElementProps) => {
  const { t } = useTranslation();
  const plusFeatureFlag = useSelector(({ arkConfig }) => arkConfig?.plusFeature?.isEnabled);

  return (
    <div className={classNames(styles.container, { [styles.visible]: visible })}>
      {plusFeatureFlag ? t('AVATAR_MESSAGE_PLUS') : t('AVATAR_MESSAGE_ADVANTAGE')}
    </div>
  );
});
