import React, { FC, ReactElement, useEffect, useState } from 'react';

import { UserSubscription } from '@arkadium/eagle-payments-api-client/dist/types/api/v1/dto/subscription.dto';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';


import { AlreadySubscribedBlock } from './AlreadySubscribedBlock/AlreadySubscribedBlock';
import { AvatarCardBlock } from './AvatarCardBlock/AvatarCardBlock';
import { FAQBlock } from './FAQBlock/FAQBlock';
import { HistoryBlock } from './HistoryBlock/HistoryBlock';
import IconsBlock from './IconsBlock/IconsBlock';
import { JoinBlock } from './JoinBlock/JoinBlock';
import styles from './PaymentAndSubscription.css';
import { StatsBlock } from './StatsBlock/StatsBlock';
import { StatusBlock } from './StatusBlock/StatusBlock';
import { globalErrorHandler } from '../../../../../utils/LogUtils';
import { AppLoader } from '../../../../atoms/AppLoader/AppLoader';
import { AppInsightService } from '../../../../services/AppInsight';
import {
  getFirstDateSubscription,
  getLastDateSubscription,
  getType,
  removeInternal
} from '../../../../services/PaymentAndSubscriptionHelpers';
import PaymentService from '../../../../services/PaymentService';
import { setActiveUserSubscriptions, setExpiredUserSubscriptions } from '../../../../store/ducks/subscription/common';

export enum SubscriptionActions {
  NONE = 'none',
  STANDARD = 'standard',
  RENEW = 'renew',
  RESTART = 'restart',
  SUBSCRIBED_EXTERNAL = 'subscribed1',
  SUBSCRIBED_BOTH = 'subscribed2',
}

export const PaymentAndSubscription: FC = React.memo(() => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(true);
  const activeSubscriptionFromRedux = useSelector(({ activeUserSubscriptions }) => activeUserSubscriptions || []);
  const expiredSubscriptionFromRedux = useSelector(({ expiredUserSubscriptions }) => expiredUserSubscriptions || []);
  const [activeSubscriptions, setActiveSubscriptions] = useState(removeInternal(activeSubscriptionFromRedux));
  const [expiredSubscriptions, setExpiredSubscriptions] = useState(removeInternal(expiredSubscriptionFromRedux));

  useEffect(() => {
    setActiveSubscriptions(removeInternal(activeSubscriptionFromRedux));
  }, [activeSubscriptionFromRedux]);

  useEffect(() => {
    if (activeSubscriptionFromRedux?.length === 0) {
      setActiveSubscriptions([]);
      PaymentService.getSubscriptions()
        .then((res) => {
          setActiveSubscriptions(removeInternal(res));
          dispatch(setActiveUserSubscriptions(res));
          setLoading(false);
        })
        .catch((err) => {
          globalErrorHandler({
            error: err,
            filename: 'PaymentAndSubscription.tsx',
            info: 'PaymentService.getSubscriptions()'
          });
          setLoading(false);
          AppInsightService.trackAppError(err, { data: 'getSubscriptions()' });
        });
    } else {
      setLoading(false);
    }

    if (expiredSubscriptionFromRedux?.length === 0) {
      setExpiredSubscriptions([]);
      PaymentService.getExpiredSubscriptions()
        .then((res) => {
          setExpiredSubscriptions(removeInternal(res));
          dispatch(setExpiredUserSubscriptions(res));
          setLoading(false);
        })
        .catch((err) => {
          globalErrorHandler({ error: err, filename: 'PaymentAndSubscription.tsx', info: 'ExpiredSubscriptions()' });
          setLoading(false);
          AppInsightService.trackAppError(err, { data: 'getExpiredSubscriptions()' });
        });
    } else {
      setLoading(false);
    }
  }, []);

  let content: ReactElement;
  const type: SubscriptionActions = getType(activeSubscriptions, expiredSubscriptions);

  switch (type) {
    case SubscriptionActions.NONE:
      content = none(t);
      break;
    case SubscriptionActions.STANDARD:
      content = standard(activeSubscriptions);
      break;
    case SubscriptionActions.RESTART:
      content = restart(expiredSubscriptions);
      break;
    case SubscriptionActions.RENEW:
      content = renew(activeSubscriptions);
      break;
    case SubscriptionActions.SUBSCRIBED_EXTERNAL:
      content = subscribed(SubscriptionActions.SUBSCRIBED_EXTERNAL, activeSubscriptions);
      break;
    case SubscriptionActions.SUBSCRIBED_BOTH:
      content = subscribed(SubscriptionActions.SUBSCRIBED_BOTH, activeSubscriptions);
      break;
    default:
  }

  return (
    <>
      {loading && (
        <div className={styles.loader}>
          <AppLoader />
        </div>
      )}
      {!loading && content}
    </>
  );
});

const none = (t: any) => (
  <div className={classNames(styles.container, styles.none)}>
    <div>
      <JoinBlock type={SubscriptionActions.NONE} />
    </div>
    <div className={styles.icons}>
      <IconsBlock />
    </div>
    <div className={styles.textInfo}>
      {t('PAYMENT_PAGE.RENEW_TEXT')}
    </div>
    <div className={styles.faq}>
      <FAQBlock />
    </div>
  </div>
);
const standard = (subscriptions: UserSubscription[]) => (
  <div className={styles.container}>
    <div className={styles.card}>
      <AvatarCardBlock
        type={SubscriptionActions.STANDARD}
        date={getFirstDateSubscription(subscriptions).startDate}
      />
    </div>
    <div className={styles.stats}>
      <StatsBlock type={SubscriptionActions.STANDARD} />
    </div>
    <div className={styles.status}>
      <StatusBlock
        date={getLastDateSubscription(subscriptions).endDate}
        planId={getLastDateSubscription(subscriptions).planId}
      />
    </div>
    <div className={styles.history}>
      <HistoryBlock />
    </div>
    <div className={styles.icons}>
      <IconsBlock />
    </div>
    <div className={styles.faq}>
      <FAQBlock />
    </div>
  </div>
);
const renew = (subscriptions: UserSubscription[]) => (
  <div className={styles.container}>
    <div className={styles.card}>
      <AvatarCardBlock type={SubscriptionActions.RENEW} date={getLastDateSubscription(subscriptions).endDate} />
    </div>
    <div className={styles.stats}>
      <StatsBlock type={SubscriptionActions.RENEW} />
    </div>
    <div className={styles.status}>
      <StatusBlock
        date={getLastDateSubscription(subscriptions).endDate}
        planId={getLastDateSubscription(subscriptions).planId}
      />
    </div>
    <div className={styles.history}>
      <HistoryBlock />
    </div>
    <div className={styles.icons}>
      <IconsBlock />
    </div>
    <div className={styles.faq}>
      <FAQBlock />
    </div>
  </div>
);
const restart = (expiredSubscriptions: UserSubscription[]) => (
  <div className={classNames(styles.container, styles.restart)}>
    <div className={styles.card}>
      <AvatarCardBlock
        type={SubscriptionActions.RESTART}
        date={getLastDateSubscription(expiredSubscriptions).endDate}
      />
    </div>
    <div className={styles.stats}>
      <StatsBlock type={SubscriptionActions.RESTART} />
    </div>
    <div className={styles.history}>
      <HistoryBlock />
    </div>
    <div className={styles.icons}>
      <IconsBlock />
    </div>
    <div className={styles.faq}>
      <FAQBlock />
    </div>
  </div>
);
const subscribed = (type: SubscriptionActions, subscriptions: UserSubscription[]) => (
  <div className={styles.container}>
    <div className={styles.card}>
      <AvatarCardBlock type={type} date={getFirstDateSubscription(subscriptions).startDate} />
    </div>
    <div className={styles.stats}>
      <StatsBlock type={type} />
    </div>
    <div>
      <AlreadySubscribedBlock type={type} />
    </div>
    <div className={styles.icons}>
      <IconsBlock />
    </div>
    <div className={styles.faq}>
      <FAQBlock />
    </div>
  </div>
);
