import React, { ReactElement } from 'react';

import classNames from 'classnames';
import { TFunction } from 'i18next';

import styles from './PlusBenefitsList.css';
import { ListCheckCircleIcon } from '../../../../../FigmaStyleguide/Icons/ListCheckCircleIcon';

interface IPlusBenefitsListProps {
  t: TFunction;
  noTopMargin?: boolean;
  isTrialAvailable: boolean;
}

const COLOR_CIRCLE_COLOR = '#FFFFFF';
const COLOR_CIRCLE_MAIN = '#12B76A';
const PLUS_BENEFITS_KEYS = [
  'SELECT_SUBSCRIPTION.PLUS_FREE_ADS',
  'SELECT_SUBSCRIPTION.PLUS_SUBSCRIBER_ONLY_GAME',
  'SELECT_SUBSCRIPTION.PLUS_BONUS_GEMS',
  'SELECT_SUBSCRIPTION.PLUS_SAVE_PERCENT',
  'SELECT_SUBSCRIPTION.PLUS_VIP_SUPPORT'
];
const renderPlusBenefitItem = (t: TFunction, tKey: string): ReactElement => (
  <li>
    <ListCheckCircleIcon checkColor={COLOR_CIRCLE_COLOR} circleColor={COLOR_CIRCLE_MAIN} />
    <p>{t(tKey)}</p>
  </li>
);

export const PlusBenefitsList = ({ t, noTopMargin, isTrialAvailable }: IPlusBenefitsListProps) => (
  <ul className={classNames(styles.advantagesList, {
    [styles.noMargin]: noTopMargin
  })}>
    {isTrialAvailable && (
      renderPlusBenefitItem(t, 'SELECT_SUBSCRIPTION.PLUS_THREE_DAY_TRIAL')
    )}
    {PLUS_BENEFITS_KEYS.map((plusBenefitKey) => renderPlusBenefitItem(t, plusBenefitKey))}
  </ul>
);
