import React, { useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import classNames from 'classnames';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import stylesConfirm from './ConfirmResetPassword.css';
import {
  defaultValues,
  passwordMinValidation,
  passwordUppercaseValidation,
  validationSchema
} from './validationSchemas/ConfirmResetPasswordSchema';
import { Input } from '../../../../atoms/HookFormInput/Input';
import { I18nText } from '../../../../atoms/i18nText/i18nText';
import { SignInUpComponents } from '../../../../constants/SignInUpComponents';
import { Button } from '../../../../FigmaStyleguide/Button/Button';
import { Analytics } from '../../../../services/Analytics/Analytics';
import { TranslationService } from '../../../../services/TranslationService';
import { UrlService } from '../../../../services/UrlService';
import UserService, { EmailCodes, ERRORS_DESCRIPTION } from '../../../../services/UserService';
import { setSignInUpState, setSnackbarData } from '../../../../store/ducks/layout';
import styles from '../RightPanelCommonStyles/RightPanelCommonStyles.css';
import { RightPanelWrapper } from '../RightPanelWrapper/RightPanelWrapper';
import stylesSignUP from '../SignUp/SignUp.css';

export const ConfirmResetPassword = React.memo(() => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors, isValid }
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
    defaultValues
  });
  const values = watch();
  const { password } = values;
  const isPasswordEmpty = password.length === 0;
  const handleResetPassword = () => {
    const code = UrlService.getQSParam(window.location.search, EmailCodes.RESET_PASSWORD_CODE);

    if (code) {
      setLoading(true);
      UserService.changePasswordByRecoveryCode({ newPassword: password, resetCode: code }).then((res) => {
        setLoading(false);

        if (res === 200) {
          void Analytics.trackEvent(Analytics.profile.resetPassword('success'));
          dispatch(
            setSnackbarData({
              isOpened: true,
              message: TranslationService.translateIt('REGISTER.RESET_PASSWORD_MESSAGE'),
              type: 'success',
              parentNode: 'rightSideMenu'
            })
          );
          dispatch(setSignInUpState(SignInUpComponents.RESET_PASSWORD_SUCCESS));
        } else {
          dispatch(
            setSnackbarData({
              isOpened: true,
              message: UserService.errorCodeToText(res),
              type: 'error',
              parentNode: 'rightSideMenu'
            })
          );
        }
      });
    } else {
      dispatch(
        setSnackbarData({
          isOpened: true,
          message: ERRORS_DESCRIPTION.UserPasswordResetCodeIsInvalid,
          type: 'error',
          parentNode: 'rightSideMenu'
        })
      );
    }
  };
  const cleanField = () => {
    setValue('confirmPassword', '');
  };

  return (
    <RightPanelWrapper headerLabelKey="REGISTER.RESET_YOUR_PASSWORD">
      <form
        onSubmit={handleSubmit(handleResetPassword)}
        className={classNames(styles.formContent, stylesConfirm.formContainer)}
      >
        <div className={stylesConfirm.inputWrapper}>
          <Input
            required
            className={styles.input}
            isValid={!errors.password}
            ariaInvalid={errors.password ? 'true' : 'false'}
            register={register}
            name="password"
            type="password"
            placeholder={TranslationService.translateIt('REGISTER.ENTER_YOUR_NEW_PASSWORD')}
            value={values.password}
            onFocus={() => {
              cleanField();
            }}
          />

          <div
            className={classNames(stylesSignUP.validationMessage, styles.validationMessageCustom, {
              [stylesSignUP.validationMessageError]:
              !isPasswordEmpty && !passwordMinValidation.isValidSync(values.password),
              [stylesSignUP.validationMessageOk]:
              !isPasswordEmpty && passwordMinValidation.isValidSync(values.password)
            })}
          >
            <span className={stylesSignUP.dot}/>
            <I18nText keyName={'REGISTER.AT_LEAST'}/>
            <span> 8 </span>
            <I18nText keyName={'REGISTER.CHARS'}/>
          </div>
          <div
            className={classNames(
              stylesSignUP.validationMessage,
              styles.validationMessageCustom,
              stylesConfirm.m30bottom,
              {
                [stylesSignUP.validationMessageError]:
                !isPasswordEmpty && !passwordUppercaseValidation.isValidSync(values.password),
                [stylesSignUP.validationMessageOk]:
                !isPasswordEmpty && passwordUppercaseValidation.isValidSync(values.password)
              }
            )}
          >
            <span className={stylesSignUP.dot}/>
            <I18nText keyName={'REGISTER.UPPERCASE_LETTER'}/>
          </div>
          <Input
            required
            className={classNames(styles.input)}
            isValid={!errors.confirmPassword}
            ariaInvalid={errors.confirmPassword ? 'true' : 'false'}
            register={register}
            name="confirmPassword"
            type="password"
            placeholder={TranslationService.translateIt('REGISTER.REENTER_YOUR_NEW_PASSWORD')}
            value={values.confirmPassword}
            showError={errors?.confirmPassword}
            errorMessage={errors?.confirmPassword?.message ?? ''}
          />
        </div>
        <div className={styles.buttonRow}>
          <Button
            className={styles.submitButton}
            onClick={handleResetPassword}
            type="submit"
            disabled={
              !isValid ||
              values.password !== values.confirmPassword ||
              !values.password ||
              !values.confirmPassword ||
              loading
            }
          >
            <I18nText keyName={'REGISTER.SUBMIT'}/>
          </Button>
        </div>
      </form>
    </RightPanelWrapper>
  );
});
