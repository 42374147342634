import React from 'react';

import { useTranslation } from "react-i18next";

import styles from './SubscriptionStar.css';
import { SubscriptionStarIcon } from '../Icons/SubscriptionStarIcon';

const SAVE_VALUE_PERCENT = '41%';

export const SubscriptionStar = ({ percentsSaved }) => {
    const { t } = useTranslation();

    return (
        <div className={styles.container}>
            <SubscriptionStarIcon />
            <div className={styles.content}>
                {t('SUBSCRIPTION_STAR.SAVE')}
                <br />
                {percentsSaved ? `${percentsSaved}%` : SAVE_VALUE_PERCENT}
            </div>
        </div>
    );
};
