import { BaseApiService } from './BaseApiService';
import { LocalStorageService } from './LocalStorage';
import { HttpUtils } from '../../utils/HttpUtils';
import { globalErrorHandler } from '../../utils/LogUtils';
import { environment } from '../config/environment';
import { LS_COOKIE_CONSTS } from '../models/Enums';
import { UserTopScores } from '../models/HighScores';
import { subscriberAvatar } from '../models/User/UserModel';

export type HighScoreModel = {
  slug: string;
  score: number;
  timestamp?: string;
  timeOffset?: number;
};

export type LeaderboardDataModel = {
  public: LeaderboardPublicDataModel;
  user: LeaderboardUserDataModel;
};

export type LeaderboardPublicDataModel = {
  day: LeaderboardPublicItemModel[];
  week: LeaderboardPublicItemModel[];
  month: LeaderboardPublicItemModel[];
};

export type LeaderboardUserDataModel = {
  day: number;
  week: number;
  month: number;
};

type LeaderboardItemModelMeta = {
  isCurrentUser: boolean;
};

export type LeaderboardPublicItemModel =
  {
    score: number;
    avatar: string;
    avatarBackground?: string;
    uid: string;
    isSubscriber?: boolean;
    name: string;
    subscriberAvatar?: subscriberAvatar;
    playingSince?: string;
    countryId?: string;
  }
  & LeaderboardItemModelMeta;

export type LeaderboardUserItemModel =
  Record<string, unknown>
  & HighScoreModel;

interface HighScoreServiceInterface {
  scoreFetch(userId: string): Promise<HighScoreModel[]>;
  scoreSave(score: HighScoreModel[], getToken: () => Promise<string>): void;
  scoreSyncLocalWithDB(getToken: () => Promise<string>): void;
  scoreAddLocal(score: HighScoreModel): HighScoreModel[];
  leaderboardFetch(slug: string, userId: string, getToken: () => Promise<string>): Promise<LeaderboardDataModel>;
}

class HighScoreService extends BaseApiService implements HighScoreServiceInterface {
  public scoreAddLocal(score: HighScoreModel): HighScoreModel[] {
    // save data to local storage
    return this.saveScoreToLocalStorage(score);
  }

  public async scoreSave(score: HighScoreModel[], getToken: () => Promise<string>) {
    // save data to DB, if user authenticated
    const apiToken = await getToken();

    await this.saveScoreToDB(score, apiToken);
  }

  public async scoreSyncLocalWithDB(getToken: () => Promise<string>) {
    // fetch data from local storage
    const highScore = this.fetchScoreFromLocalStorage();

    if (highScore && highScore.length > 0) {
      const apiToken = await getToken();

      await this.saveScoreToDB(highScore, apiToken);
    }
  }

  public async scoreFetch(userId: string): Promise<HighScoreModel[]> {
    return await this.fetchScoreFromDB(userId);
  }

  public async leaderboardFetch(
    slug: string,
    userId: string,
    getToken: () => Promise<string>
  ): Promise<LeaderboardDataModel> {
    const leaderboardData: LeaderboardDataModel = {
      public: {
        day: [],
        week: [],
        month: []
      },
      user: {
        day: 0,
        week: 0,
        month: 0
      }
    };

    leaderboardData.public = await this.fetchLeaderboardPublicFromDB(slug);

    if (userId) {
      let userInRange = 0;

      leaderboardData.public.day.forEach((item) => {
        if (item.uid === userId) {
          item.isCurrentUser = true;
          leaderboardData.user.day = item.score;
          userInRange += 1;
        }
      });

      leaderboardData.public.week.forEach((item) => {
        if (item.uid === userId) {
          item.isCurrentUser = true;
          leaderboardData.user.week = item.score;
          userInRange += 1;
        }
      });

      leaderboardData.public.month.forEach((item) => {
        if (item.uid === userId) {
          item.isCurrentUser = true;
          leaderboardData.user.month = item.score;
          userInRange += 1;
        }
      });

      if (userInRange !== 3) {
        const apiToken = await getToken();
        const leaderboardUserData = await this.fetchLeaderboardUserFromDB(slug, apiToken);

        if (leaderboardUserData.day) {
          leaderboardData.user.day = leaderboardUserData.day;
        }

        if (leaderboardUserData.week) {
          leaderboardData.user.week = leaderboardUserData.week;
        }

        if (leaderboardUserData.month) {
          leaderboardData.user.month = leaderboardUserData.month;
        }
      }
    }

    return leaderboardData;
  }

  async getTopScores(slug: string, getToken: () => Promise<string>): Promise<UserTopScores> {
    const apiToken = await getToken();
    const options = {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${apiToken}`
      }
    };

    return HttpUtils.fetch(
      `${environment.SCORE_API_BASE_URL}/score/arena/user/game/${slug}?arenaDomain=${
        environment.ARENA_DOMAIN
      }&timeOffset=${new Date().getTimezoneOffset()}`,
      options,
      true
    );
  }

  private saveScoreToLocalStorage(score: HighScoreModel): HighScoreModel[] {
    const highScoreJson = LocalStorageService.getItem(LS_COOKIE_CONSTS.HIGH_SCORE);
    let highScore: HighScoreModel[] = [];

    if (highScoreJson) {
      try {
        highScore = JSON.parse(highScoreJson).filter(item => typeof item.score === 'number') as HighScoreModel[];
      } catch (e) {
        globalErrorHandler({ error: e, filename: 'HighScoreService.ts', info: 'saveScoreToLocalStorage()' });
      }
    }

    highScore.push(score);
    LocalStorageService.setItem(LS_COOKIE_CONSTS.HIGH_SCORE, JSON.stringify(highScore));
    return highScore;
  }

  private clearScoreFromLocalStorage() {
    LocalStorageService.removeItem(LS_COOKIE_CONSTS.HIGH_SCORE);
  }

  private fetchScoreFromLocalStorage(): HighScoreModel[] {
    const highScoreJson = LocalStorageService.getItem(LS_COOKIE_CONSTS.HIGH_SCORE);
    let highScore: HighScoreModel[] = [];

    if (highScoreJson) {
      try {
        highScore = JSON.parse(highScoreJson) as HighScoreModel[];
      } catch (e) {
        globalErrorHandler({ error: e, filename: 'HighScoreService.ts', info: 'fetchScoreFromLocalStorage()' });
      }
    }

    return highScore;
  }

  private async saveScoreToDB(score: HighScoreModel[], apiToken: string) {
    // save data to azure table storage
    let saved = false;

    try {
      const url = `${environment.SCORE_API_BASE_URL}/score?arenaDomain=${environment.ARENA_DOMAIN}`;
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${apiToken}`
        },
        body: JSON.stringify(score) // body data type must match "Content-Type" header
      };

      await HttpUtils.fetch(url, options, false);
      saved = true;
    } catch (error) {
      this.trackError(
        error,
        'An error occurred while try to save user high scores to server. See app insight logs for details.'
      );
      throw error;
    }

    if (saved) {
      this.clearScoreFromLocalStorage();
    }
  }

  private async fetchScoreFromDB(userId: string) {
    let highScore: HighScoreModel[] = [];

    try {
      const url = `${environment.SCORE_API_BASE_URL}/score/arena/user/${userId}/timeFrame/all?arenaDomain=${
        environment.ARENA_DOMAIN
      }&timeOffset=${new Date().getTimezoneOffset()}`;
      const options = {
        method: 'GET'
      };

      highScore = (await HttpUtils.fetch(url, options)) as HighScoreModel[];
    } catch (error) {
      this.trackError(
        error,
        'An error occurred while try to fetch user high score from server. See app insight logs for details.'
      );
      throw error;
    }

    return highScore;
  }

  private async fetchLeaderboardPublicFromDB(slug: string): Promise<LeaderboardPublicDataModel> {
    const leaderboardQueryLimit = 150;
    const leaderboard: LeaderboardPublicDataModel = {
      day: [],
      week: [],
      month: []
    };

    try {
      const dayUrl = `${
        environment.SCORE_API_BASE_URL
      }/score/arena/leaderboard/${slug}/day/?limit=${leaderboardQueryLimit}&arenaDomain=${
        environment.ARENA_DOMAIN
      }&timeOffset=${new Date().getTimezoneOffset()}`;
      const weekUrl = `${
        environment.SCORE_API_BASE_URL
      }/score/arena/leaderboard/${slug}/week/?limit=${leaderboardQueryLimit}&arenaDomain=${
        environment.ARENA_DOMAIN
      }&timeOffset=${new Date().getTimezoneOffset()}`;
      const monthUrl = `${
        environment.SCORE_API_BASE_URL
      }/score/arena/leaderboard/${slug}/month/?limit=${leaderboardQueryLimit}&arenaDomain=${
        environment.ARENA_DOMAIN
      }&timeOffset=${new Date().getTimezoneOffset()}`;
      const options = {
        method: 'GET'
      };
      const dayRequest = HttpUtils.fetch(dayUrl, options);
      const weekRequest = HttpUtils.fetch(weekUrl, options);
      const monthRequest = HttpUtils.fetch(monthUrl, options);
      const [dayResult, weekResult, monthResult] = await Promise.all([dayRequest, weekRequest, monthRequest]);

      leaderboard.day = dayResult as LeaderboardPublicItemModel[];
      leaderboard.week = weekResult as LeaderboardPublicItemModel[];
      leaderboard.month = monthResult as LeaderboardPublicItemModel[];
    } catch (error) {
      this.trackError(
        error,
        'An error occurred while try to fetch leaderboard data from server. See app insight logs for details.'
      );
      throw error;
    }

    return leaderboard;
  }

  private async fetchLeaderboardUserFromDB(slug: string, apiToken: string): Promise<LeaderboardUserDataModel> {
    let highScore: LeaderboardUserDataModel;

    try {
      const url = `${environment.SCORE_API_BASE_URL}/score/arena/user/game/${slug}?&arenaDomain=${
        environment.ARENA_DOMAIN
      }&timeOffset=${new Date().getTimezoneOffset()}`;
      const options = {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${apiToken}`
        }
      };

      highScore = (await HttpUtils.fetch(url, options)) as LeaderboardUserDataModel;
    } catch (error) {
      this.trackError(
        error,
        'An error occurred while try to fetch leaderboard data for user from server. See app insight logs for details.'
      );
      throw error;
    }

    return highScore;
  }
}

export default new HighScoreService();
