import React from 'react';

import { useTranslation } from "react-i18next";

import styles from './GemShopErrorView.css';
import { I18nText } from '../../atoms/i18nText/i18nText';
import { Button } from '../../FigmaStyleguide/Button/Button';
import { UrlService } from '../../services/UrlService';
type GemShopErrorViewProps = {
    tryAgainHandler: () => void;
    goBackHandler: () => void;
};
export const GemShopErrorView = React.memo(({ tryAgainHandler, goBackHandler }: GemShopErrorViewProps) => {
  const { t } = useTranslation();
  
    return (
        <div className={styles.gemErrorContainer}>
            <img
                className={styles.image}
                src={UrlService.createURL('/images/gems/graphics/safe.png')}
                alt={'Safe with gems graphic'}
            />
            <I18nText as="h2" className={styles.message} keyName="GEMS_SHOP_ERROR" />
            <Button onClick={tryAgainHandler}>{t('GEM_SHOP_ERROR_VIEW.TRY_AGAIN')}</Button>
            <Button onClick={goBackHandler}>{t('GEM_SHOP_ERROR_VIEW.GO_BACK')}</Button>
        </div>
    );
});
GemShopErrorView.displayName = 'GemShopErrorView';
