import React, { FC, useEffect } from 'react';

import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import styles from './PlusSelectSubscription.css';
import { AppLoader } from '../../../../../atoms/AppLoader/AppLoader';
import { I18nText } from '../../../../../atoms/i18nText/i18nText';
import { ArkadiumPlusLogo } from '../../../../../atoms/Icons/Styleguide/ArkadiumPlusLogo';
import { NavLink } from '../../../../../atoms/Link/Link';
import { PaymentType, RecurlyPlans } from '../../../../../constants/RecurlyPurchase';
import {
  checkTrialPeriodAvailability,
  getPlanByCode,
  SubscriptionPlans
} from '../../../../../constants/SubscriptionPlan';
import { UrlService } from '../../../../../services/UrlService';
import {
  getSubscriptionPlans
} from '../../../../../templates/Profile/Tabs/PaymentAndSubscription/PlusPaymentAndSubscription/components/PlusJoinBlock/PlusJoinBlock';
import { PlusBenefitsList } from '../PlusBenefitsList/PlusBenefitsList';
import PlusCard from '../PlusCard/PlusCard';

export const PlusSelectSubscription: FC = React.memo(() => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const plans = useSelector(({ subscriptionPlans }) => subscriptionPlans);
  const userExpiredSubscriptions = useSelector(({ expiredUserSubscriptions }) => expiredUserSubscriptions);
  const plusFeatureFlag = useSelector(({ arkConfig }) => arkConfig?.plusFeature?.isEnabled);
  const currentLang = useSelector(({ currentLang }) => currentLang);
  const isTrialAvailable = checkTrialPeriodAvailability(userExpiredSubscriptions);
  const annualPlanName = RecurlyPlans(plusFeatureFlag)[PaymentType.subscription][!isTrialAvailable ?
    SubscriptionPlans.ANNUAL :
    SubscriptionPlans.ANNUAL_TRIAL].plan;
  const monthlyPlanName = RecurlyPlans(plusFeatureFlag)[PaymentType.subscription][!isTrialAvailable ?
    SubscriptionPlans.MONTHLY :
    SubscriptionPlans.MONTHLY_TRIAL].plan;

  PlusSelectSubscription.displayName = 'SelectSubscription';

  useEffect(() => {
    if (!plans) {
      getSubscriptionPlans(dispatch, null);
    }
  }, [plans]);

  return (
    <>
      <div className={styles.topBlock}>
        <div className={styles.mainInfo}>
          <div className={styles.leftSide}>
            <ArkadiumPlusLogo />
          </div>
          <div className={styles.rightSide}>
            <PlusBenefitsList t={t} noTopMargin isTrialAvailable={isTrialAvailable} />
          </div>
        </div>
      </div>
      <div className={styles.advantagesContainer}>
        <div className={styles.advantagesRow}>
          {plans?.length ? (
            <>
              <PlusCard
                plan={getPlanByCode(annualPlanName, plans)}
                planType={SubscriptionPlans.ANNUAL}
              />
              <PlusCard
                plan={getPlanByCode(monthlyPlanName, plans)}
                planType={SubscriptionPlans.MONTHLY}
              />
            </>
          ) : (
            <AppLoader />
          )}
        </div>

        <div className={styles.aboutRow}>
          <div className={styles.aboutSubscription}>
            <Trans i18nKey="SELECT_SUBSCRIPTION.PLUS_SUBSCRIPTIONS_TERMS" />
          </div>
          <div className={styles.subscriptionLinks}>
            <NavLink to="/arkadium-player-agreement.pdf" target="_blank" rel="noopener">
              <I18nText keyName="PLAYER_AGREEMENT" />
            </NavLink>{','}&nbsp;
            <NavLink to="/arkadium-plus-agreement.pdf" target="_blank" rel="noopener">
              <I18nText keyName="PLUS_SUBSCRIPTION_AGREEMENT" />
            </NavLink>&nbsp;
            {t('SELECT_SUBSCRIPTION.AND')}&nbsp;
            <NavLink to={UrlService.createPrivacyPolicyURL(currentLang, true)} target="_blank" rel="noopener">
              <I18nText keyName="FOOTER_PRIVACY_POLICY" />
            </NavLink>
          </div>
        </div>
      </div>
    </>
  );
});
