import * as Cookie from 'cookie';

import { Analytics } from './Analytics';
import { FingerprintService } from './FingerprintJS/FingerprintService';
import { MiscUtils } from '../../../utils/MiscUtils';
import { environment } from '../../config/environment.production';
import { LS_COOKIE_CONSTS } from '../../models/Enums';
import { IGame } from '../../models/Game/Game';
import { UserSubscription } from '../../models/Subscription/SubscriptionData';
import AbTestService from '../AbTestService';
import { CookieService } from '../CookieService';
import { DeviceDetector } from '../DeviceDetector';
import { getAiIsVirtualItemBuy, getGemsVirtualItemsSpentFromLocalStorage, getUserInventory } from '../GemsService';
import { LocalStorageService } from '../LocalStorage';
import { UrlService } from '../UrlService';
import UserService from '../UserService';

const arenaVersion = 'arkadium.com';
const buildVersion = environment.ADO_BUILD_ID;

type KeyValue = {
    [key: string]: any;
};

export type AnalyticsEvent = {
    name: string;
    data: KeyValue;
};

export type AnalyticsEventsPair = {
    ga: AnalyticsEvent;
    ai: AnalyticsEvent;
};

export async function makeEventsPair(
    gaEvent: AnalyticsEvent | null,
    aiEvent: Promise<AnalyticsEvent> | null
): Promise<AnalyticsEventsPair> {
    return {
        ga: gaEvent,
        ai: await aiEvent,
    } as AnalyticsEventsPair;
}

export enum PwaPlatformsMobile {
    ANDROID = 'Android',
    IOS = 'iOS',
    OTHER = 'other',
}

export type MobileBookmarkPwaData = {
    category?: string;
    game?: IGame;
    platform: PwaPlatformsMobile;
};

export enum AnalyticsEventAction {
    CLICK = 'Click',
    IMPRESSION = 'Impression',
    START = 'Start',
    QUERY = 'Query',
    ADBLOCKDISABLED = 'Adblock Disabled',
    INSTALL = 'Install',
    VISITS = 'Visits',
    VISIT = 'Visit',
    DRAG = 'Drag',
    CANCEL = 'Cancel',
    YES = 'Yes',
    NO = 'No',
    LOGIN = 'Login',
    EMAILOPTIN = 'emailOptIn',
    SKIP_AD = 'SkipAd',
    POWER_UP = 'PowerUp',
}

export class AnalyticsEventBuilder {
    static recommendedEventBuilder(event: AnalyticsEventBuilder) {
        if (Analytics.getRecPreviousGame() !== '') {
            event.setRecPreviousGame(Analytics.getRecPreviousGame());
            event.setRecommendedGamesStart(AnalyticsEventAction.YES);
        } else {
            event.setRecommendedGamesStart(AnalyticsEventAction.NO);
        }

        if (Analytics.getPlayAgainStart()) {
            event.setPlayAgainStart(Analytics.getPlayAgainStart());
        }
    }

    static playAgainStartEventBuilder(event: AnalyticsEventBuilder) {
        if (Analytics.getPlayAgainStart()) {
            event.setPlayAgainStart(Analytics.getPlayAgainStart());
        }
    }

    public options = {};

    private name: string | number = '';

    private deviceDetector: DeviceDetector;

    constructor() {
        this.deviceDetector = new DeviceDetector();
    }

    async baseEvent(): Promise<AnalyticsEventBuilder> {
        this.add(await this.getEventBaseParams());
        return this;
    }

    async pageViewEvent(): Promise<AnalyticsEventBuilder> {
        this.add(await this.getPageviewBaseParams());
        return this;
    }

    async setGame(game: IGame): Promise<AnalyticsEventBuilder> {
        this.add({ game: game.slug });
        this.add({ isFavorited: game?.isFavorite });
        const info = await MiscUtils.getSdKInfo();

        this.add({ gameRelease: game.version });
        info &&
            this.add({
                gameVersion: info.GameVersion !== undefined ? info?.GameVersion : '',
                nestVersion: info.NestVersion !== undefined ? info?.NestVersion : '',
                sdkVersion: info.SdkVersion !== undefined ? info?.SdkVersion : '',
            });
        // Added not required arg and conditional arg's props reading, because when ts is turned on we get error in
        // GeneralAnalyticsAi.ts, NewHomeAnalyticsAi.ts, ProfileAnalyticsAi.ts when call setGame(game) without 2nd arg

        if (!MiscUtils.isServer) {
            (window as any).aiGameOptions = this.options;
        }

        return this;
    }

    setRecPreviousGame(gameName: string): AnalyticsEventBuilder {
        this.add({ recPreviousGame: gameName });
        return this;
    }

    setRecommendedGamesStart(value: string): AnalyticsEventBuilder {
        this.add({ recommendedGamesStart: value });
        return this;
    }

    setPlayAgainStart(value: string): AnalyticsEventBuilder {
        this.add({ playAgainStart: value });
        return this;
    }

    setNonInteraction(noninteraction: boolean): AnalyticsEventBuilder {
        this.add({ noninteraction });
        return this;
    }

    setEventAction(action: AnalyticsEventAction | string | number): AnalyticsEventBuilder {
        this.add({ action });
        return this;
    }

    setName(name: string) {
        this.name = name;
    }

    setCustom(key: string, value: any): AnalyticsEventBuilder {
        this.add({ [key]: value });
        return this;
    }

    setEventGaAction(action: string | number) {
        this.add({ action });
    }

    setEventGaCategory(category: string) {
        this.add({ category });
    }

    setNameGA(name: string) {
        this.add({ name });
    }

    setEventGaLabel(label: string) {
        this.add({ label });
    }

    setGaNonInteraction(nonInteraction: boolean): AnalyticsEventBuilder {
        this.add({ nonInteraction });
        return this;
    }

    build() {
        return {
            name: this.name,
            data: { ...this.options },
        } as AnalyticsEvent;
    }

    private add(data: KeyValue = {}) {
        this.options = {
            ...this.options,
            ...data,
        };
    }

    private getReferrer() {
        let urlReferrer: string;
        const refDirect = window.document.referrer === '';
        const isReffererOuter = window.document.referrer.indexOf(window.location.host) === -1 && !refDirect;

        if (isReffererOuter) {
            urlReferrer = window.document.referrer;
        }

        return urlReferrer;
    }

    private async getEventBaseParams(): Promise<KeyValue> {
        // let firstDayVisit: string; // Return date for first visit in format: 31_03_2019 based on google cookie
        let bookmarkType: string;
        let subscriptionId: string;
        const affiliateCookie = CookieService.getArkCookie(LS_COOKIE_CONSTS.ARK_AFFILIATE);
        const cookies = Cookie.parse(window.document.cookie);
        const userType = cookies.ark_visitor_main;
        const xsollaId = await asyncUidFromStore();
        const ABTestSlot1 = cookies.ark_abtest_variation;
        const abvariations = AbTestService.getVariationFromStore() || undefined;
        const affiliateName = affiliateCookie ? affiliateCookie : undefined;
        const utmInfo = UrlService.getUtmInfo();
        const offsitepromo =
            UrlService.getQSParam(window.location.search, 'arkpromo') || LocalStorageService.getItem('arkpromo', true);
        const subscriptionBonus = UserService.isUserHasBonusOnly() || undefined;
        const storeState = await asyncStoreStateWhenReady();
        const subscriptions = storeState?.activeUserSubscriptions as UserSubscription[];
        const subscriptionIdFromCookie = CookieService.getArkCookie(LS_COOKIE_CONSTS.SUBSCRIPTION_ID);

        if (subscriptions?.length > 0) {
            subscriptionId = subscriptions[0].subscriptionId;
        } else if (subscriptionIdFromCookie) {
            subscriptionId = subscriptionIdFromCookie;
        }

        if (Analytics.getBookmarkType()) {
            bookmarkType = Analytics.getBookmarkType();
        }

        const userInventory = getUserInventory();
        const virtualItemBuy = getAiIsVirtualItemBuy();
        const virtualItemSpent = getGemsVirtualItemsSpentFromLocalStorage();
        // FingerprintJS global cd
        const fingerprintGlobalCDs = FingerprintService.detected || (await FingerprintService.detect());
        const paramSet = {
            userType,
            xsollaId,
            bookmarkType,
            ABTestSlot1,
            offsitepromo,
            abvariations,
            ...utmInfo,
            subscriptionId,
            subscriptionBonus,
            affiliateName,
            userInventory,
            virtualItemBuy,
            virtualItemSpent,
            ...fingerprintGlobalCDs,
        };

        Object.keys(paramSet).forEach((key) => {
            paramSet[key] === undefined && delete paramSet[key];
        });

        return {
            ...paramSet,
            domain: UrlService.domainWithoutWWW,
            pageType: UrlService.getPageType(),
            deviceType: this.deviceDetector.DetectDevice(),
            loggedIn: UserService.isUserLoggedIn(),
            screenResolution: window.innerWidth + 'x' + window.innerHeight,
            arenaVersion,
            buildVersion,
        };
    }

    private async getPageviewBaseParams(): Promise<KeyValue> {
        const utmInfo = UrlService.getUtmInfo();
        const paramSet = {
            ...utmInfo,
            urlReferrer: this.getReferrer(),
        };

        Object.keys(paramSet).forEach((key) => {
            (paramSet[key] === undefined || paramSet[key] === null) && delete paramSet[key];
        });

        const userInventory = getUserInventory();
        const virtualItemBuy = getAiIsVirtualItemBuy();
        const storeState = await asyncStoreStateWhenReady();
        const loggedIn = Boolean(storeState?.user);
        // FingerprintJS global cd
        const fingerprintGlobalCDs = FingerprintService.detected || (await FingerprintService.detect());

        return {
            ...paramSet,
            pageType: UrlService.getPageType(storeState),
            deviceType: this.deviceDetector.DetectDevice(),
            loggedIn,
            screenResolution: window.innerWidth + 'x' + window.innerHeight,
            arenaVersion,
            buildVersion,
            userInventory,
            virtualItemBuy,
            ...fingerprintGlobalCDs,
        };
    }
}

const checkIsUidProcessed = (isUserLoggedIn: boolean, userUid: any): boolean =>
    isUserLoggedIn
        ? Boolean(userUid) // if we know from API that user is logged in, but no uid in store => not loaded
        : true; // if user is not logged in we may not wait => proceed as for loaded
const asyncUidFromStore = async () => {
    const checkStoreLoadedForUid = (resolve) => {
        const isUserLoggedIn = UserService.isUserLoggedIn();
        const userUid = UserService.getUserFromStore()?.uid;
        const isUidProcessed = checkIsUidProcessed(isUserLoggedIn, userUid);

        if (isUidProcessed) {
            resolve(userUid);
        } else {
            setTimeout(() => {
                checkStoreLoadedForUid(resolve);
            }, 200);
        }
    };
    const uidFromReadyStore = new Promise(checkStoreLoadedForUid);
    const finalUid = await uidFromReadyStore;

    return finalUid;
};

export const isXsollaIdCorrect = (isUserLoggedIn: boolean, xsollaId: any) => {
    const userUid = UserService.getUserFromStore()?.uid;
    const userUidProcessed = checkIsUidProcessed(isUserLoggedIn, userUid);

    if (userUidProcessed && isUserLoggedIn) {
        return xsollaId && xsollaId === userUid;
    } else {
        return !xsollaId;
    }
};

export const asyncStoreStateWhenReady = async () => {
    const checkStoreReady = (resolve) => {
        const store = (window as any)?.STORE;

        if (store) {
            resolve(store);
        } else {
            setTimeout(() => {
                checkStoreReady(resolve);
            }, 200);
        }
    };
    const asyncStore = new Promise(checkStoreReady);
    const readyStore = await asyncStore;
    const state = readyStore ? (readyStore as any)?.getState?.() : undefined;

    return state;
};
