import { UserSubscription } from '@arkadium/eagle-payments-api-client/dist/types/api/v1/dto/subscription.dto';

import { environment } from '../config/environment';
import { SubscriptionPlan } from '../models/Subscription/SubscriptionData';

export type RecurlySubscriptionNames =
  'Annual'
  | 'Monthly'
  | 'Annual_Trial'
  | 'Monthly_Trial';

export const SubscriptionPlans: {
  ANNUAL: RecurlySubscriptionNames;
  MONTHLY: RecurlySubscriptionNames,
  ANNUAL_TRIAL: RecurlySubscriptionNames,
  MONTHLY_TRIAL: RecurlySubscriptionNames
} = {
  ANNUAL: 'Annual',
  ANNUAL_TRIAL: 'Annual_Trial',
  MONTHLY: 'Monthly',
  MONTHLY_TRIAL: 'Monthly_Trial'
};

export const getPlanByCode = (code: string, plans: SubscriptionPlan[]) => {
  if (!plans) {
    return null;
  }

  return plans.flat().find(item => item?.code === code) || null;
};

export const getPlanNameByCode = (plan: string): string => {
  const match = Object.keys(SubscriptionPlans)
    .sort((a, b) => b.length - a.length)
    .find(key => plan?.toUpperCase().includes(key));

  return match ? SubscriptionPlans[match] : '';
};


export const checkTrialPeriodAvailability = (expiredSubscriptions: UserSubscription[]): boolean => {
  // If there are no expired subscriptions, return true (trial is available)
  if (!expiredSubscriptions?.length) {
    return true;
  }

  // Check each subscription and determine if the trial is available
  return expiredSubscriptions.every(s => {
    // Check if the subscription plan is a trial plan by checking if the planId ends with '_trial'
    const isTrialPlan = s.planId?.trim().endsWith('_trial') ?? false;
    // If trialEndDate exists, check if the trial has expired
    // The trial is expired if the current date is greater than the trialEndDate
    const isTrialExpired = s.trialEndDate
      ? new Date() > new Date(s.trialEndDate) // Trial expired if current date > trialEndDate
      : false; // If trialEndDate is null, the trial is considered not expired

    // Trial is available if:
    // 1. The subscription is not a trial plan
    // 2. Or if it's a trial plan but the trial hasn't expired
    return !isTrialPlan || !isTrialExpired;
  });
};


export const isTrialSubscriptionActive = (userActiveSubscriptions: UserSubscription[]): boolean =>
  userActiveSubscriptions?.every(s => s.trialEndDate && new Date() < new Date(s.trialEndDate));


export const checkIsActiveTrialPeriod = (activeUserSubscriptions: UserSubscription[]): boolean => (
  activeUserSubscriptions?.length > 0 &&
  activeUserSubscriptions?.every(s => s.planId.endsWith('_trial') && new Date() < new Date(s.trialEndDate))
);

export enum SUBSCRIPTION_PLAN_NAME {
  MONTHLY = 'monthly',
  ANNUAL = 'annual',
  NONE = 'none',
  UNKNOWN = 'unknown',
}

export const getPlanName = (planId: string): SUBSCRIPTION_PLAN_NAME => {
  let type: SUBSCRIPTION_PLAN_NAME;

  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  if (planId && planId === `${environment.RECURLY_PLAN_PREFIX}aasub2025_arkcom_${SubscriptionPlans.MONTHLY}`) {
    type = SUBSCRIPTION_PLAN_NAME.MONTHLY;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  } else if (planId && planId === `${environment.RECURLY_PLAN_PREFIX}aasub2025_arkcom_${SubscriptionPlans.ANNUAL}`) {
    type = SUBSCRIPTION_PLAN_NAME.ANNUAL;
  } else if (!planId) {
    // if user not logged in or is not subscriber
    type = SUBSCRIPTION_PLAN_NAME.NONE;
  } else {
    // if new one plan added and not handled - to get info and check
    type = `${SUBSCRIPTION_PLAN_NAME.UNKNOWN} ('${planId}')` as SUBSCRIPTION_PLAN_NAME;
  }

  return type;
};
