import React, { FC } from 'react';

import classNames from 'classnames';
import { useTranslation } from "react-i18next";

import styles from './AlreadySubscribedBlock.css';
import { NavLink } from '../../../../../atoms/Link/Link';
import { HeaderSideMenuTabs } from '../../../../../constants/HeaderSideMenuTabs';
import { reduxStore } from '../../../../../store';
import { setSideMenuActivePage, setSideMenuOpened } from '../../../../../store/ducks/layout';

interface AlreadySubscribedBlockProps {
    type: string;
}

const { store } = reduxStore;

export const AlreadySubscribedBlock: FC<AlreadySubscribedBlockProps> = React.memo(
    ({ type }: AlreadySubscribedBlockProps) => {
      const { t } = useTranslation();
        const types = type === 'subscribed1' ? 'oneSubscription' : 'twoSubscriptions';

        return (
            <div className={classNames(styles.container, styles[types])}>
                {types === 'oneSubscription' && (
                    <p>
                      {t('ALREADY_SUBSCRIBED_BLOCK.ONE_SUB_START_TEXT')}
                      &nbsp;
                      <strong>{t('ALREADY_SUBSCRIBED_BLOCK.APP_STORE')}</strong>
                      &nbsp;
                      {t('ALREADY_SUBSCRIBED_BLOCK.OR')}
                      &nbsp;
                      <strong>{t('ALREADY_SUBSCRIBED_BLOCK.GOOGLE_PLAY')}</strong>
                      &nbsp;
                      {t('ALREADY_SUBSCRIBED_BLOCK.ONE_SUB_END_TEXT')}
                    </p>
                )}
                {types === 'twoSubscriptions' && (
                    <p>
                      {t('ALREADY_SUBSCRIBED_BLOCK.TWO_SUB_START_TEXT')}
                      &nbsp;
                      <strong>{t('ALREADY_SUBSCRIBED_BLOCK.APP_STORE')}</strong>
                      &nbsp;
                      {t('ALREADY_SUBSCRIBED_BLOCK.OR')}
                      &nbsp;
                      <strong>{t('ALREADY_SUBSCRIBED_BLOCK.GOOGLE_PLAY')}</strong>
                      &nbsp;
                      {t('ALREADY_SUBSCRIBED_BLOCK.TWO_SUB_END_TEXT')}
                    </p>
                )}
                <p className={styles.help}>
                  {t('ALREADY_SUBSCRIBED_BLOCK.NEED_HELP')}
                  &nbsp;
                    <NavLink
                        className={styles.link}
                        onClick={() => {
                            store.dispatch(setSideMenuActivePage(HeaderSideMenuTabs.HELP_TAB));
                            store.dispatch(setSideMenuOpened(true));
                        }}
                    >
                      {t('ALREADY_SUBSCRIBED_BLOCK.CONTACT_SUPPORT')}
                    </NavLink>
                </p>
            </div>
        );
    }
);
