import React from 'react';

import { useSelector } from 'react-redux';

import { I18nText } from '../../../../atoms/i18nText/i18nText';
import { Button } from '../../../../FigmaStyleguide/Button/Button';
import UserService from '../../../../services/UserService';
import styles from '../RightPanelCommonStyles/RightPanelCommonStyles.css';
import { RightPanelWrapper } from '../RightPanelWrapper/RightPanelWrapper';
import { StillNeedHelp } from '../StillNeedHelp/StillNeedHelp';

export const AfterSignUpSocial = React.memo(() => {
    const userName = useSelector((state) => state.user?.name || 'FanGamer2000');
    const handlePlay = () => {
        UserService.closePanel();
    };

    return (
        <RightPanelWrapper headerLabelKey="REGISTER.CONGRATULATIONS_SOCIAL_SIGN_UP_P1">
            <span className={styles.userName}>{userName}</span>
            <I18nText className={styles.header} keyName={'REGISTER.CONGRATULATIONS_SOCIAL_SIGN_UP_P2'} />{' '}
            <div className={styles.subHeader}>
                <I18nText className={styles.subHeader} keyName={'REGISTER.CONGRATULATIONS_SOCIAL_SIGN_UP_ACCOUNT'} />{' '}
            </div>
            <I18nText className={styles.takeBreak} keyName={'REGISTER.CONGRATULATIONS_SOCIAL_SIGN_UP_TAKE_A_BREAK'} />
            <Button className={styles.btnOk} onClick={handlePlay}>
                <I18nText keyName={'PLAY'} />
            </Button>
            <StillNeedHelp />
        </RightPanelWrapper>
    );
});
AfterSignUpSocial.displayName = 'AfterSignUpSocial';
