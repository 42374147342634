import { detectIncognito } from "detectincognitojs";

import { checkAdblockersListChromium } from './AdBlockers/checkExtensions';
import { CookieService } from './CookieService';
import { SessionStorageService } from './SessionStorage';
import { devConsole } from '../../utils/DevConsole';
import { MiscUtils } from '../../utils/MiscUtils';
import { GameState } from '../models/Enums';
import { setGameState } from '../store/ducks/games';

const AD_BLOCKER_TRAP_FAKE_COOKIE = 'fake_ads_tracker';

export const AD_BLOCKER_LAST_CHECK_SESSION_STORAGE_KEY = 'AD_BLOCKER_LAST_CHECK_LOCALSTORAGE_KEY';

class AdBlockDetector {
    public adsBlocked: boolean = undefined;
    public chromiumAdblockersInstalled: string[] = undefined;
    public adsCookieBlocked: boolean = false;
    public adsFetchBlocked: boolean = false;
    public adsRemoved: boolean = false;
    public adsHidden: boolean = false;

    get adBlockerUsed(): boolean {
        const isVideoBlocked = this.checkVideoAds();
        const blockedByProp = // first prop is defined and true, just link to jump directly into the detector method in IDE
            (!!this.updateAdsCookieBlocked && this.adsCookieBlocked) || // fake ads cookie was removed + chromiumAdblockersInstalled
            (!!this.updateAdsFetchBlocked && this.adsFetchBlocked) || // fetch was blocked + chromiumAdblockersInstalled
            (!!this.updateAdsRemoved && this.adsRemoved) || // '[class*="adContainer"]' not exist OR has no inner HTML
            (!!this.updateAdsHidden && this.adsHidden); // <display-ad-component> isNotDisplayed || isNotVisible || isTransparent || isShrinked
        const blockedByVideo = isVideoBlocked && blockedByProp; // !important
        const blockedByLibrary = this.adsBlocked && blockedByProp; // !important
        const blockedCombo = isVideoBlocked && this.adsBlocked; // even when no other smaller blocked props here // !important
        const displayAdsHidden = this.adsShown && this.adsHidden; // for uBlock Origin & AdGuard, now protected from false-positive detection
        const isAdBlockerUsed = this.shouldDoAdBlockerDetection(
            blockedCombo || blockedByVideo || blockedByLibrary || displayAdsHidden
        );

        SessionStorageService.setItem(
            AD_BLOCKER_LAST_CHECK_SESSION_STORAGE_KEY,
            JSON.stringify({
                isAdBlockerUsed,
                reason: {
                    blockedCombo,
                    blockedByVideo,
                    blockedByLibrary,
                    displayAdsHidden,
                },
                timestamp: new Date().getTime(),
                chromiumAdblockersInstalled: this.chromiumAdblockersInstalled,
                adsBlocked: this.adsBlocked,
                adsCookieBlocked: this.adsCookieBlocked,
                adsFetchBlocked: this.adsFetchBlocked,
                adsRemoved: this.adsRemoved,
                adsHidden: this.adsHidden,
                adsVideoBlocked: isVideoBlocked,
            })
        );
        return isAdBlockerUsed;
    }

    public async init(): Promise<void> {
        SessionStorageService.removeItem(AD_BLOCKER_LAST_CHECK_SESSION_STORAGE_KEY);
        // #144044 - AdBlocker detection update - based on https://z0ccc.github.io/extension-fingerprints/
        await this.detectIncognitoMode();

        if (this.shouldDoAdBlockerDetection(this.chromiumAdblockersInstalled === undefined)) {
            checkAdblockersListChromium(this.isGoodConnection(10)).then((result) => {
                // not await to not wait for this for initial check (can be too long)
                this.chromiumAdblockersInstalled = result;
            });
        }
    }

    public shouldDoAdBlockerDetection(mainCondition: boolean): boolean {
        return (
            !MiscUtils.isServer && // not on server
            !this.isIncognitoMode && // no extensions in incognito mode
            this.isGoodConnection(1) && // to prevent bad UX if network has issues
            this.isDesktop() && // no extensions on mobile
            mainCondition // !important
        );
    }

    public check() {
        const _this = this;

        this.updateAdsHidden(); // !important - with AdGuard it's too late to detect it inside update() method
        setTimeout(() => _this.adsShown && _this.update(), 2000);
    }

    public async update() {
        // call on page load (check display ads) & on game container render (check preroll ads)
        if (!this.adsShown) {
            return;
        }

        const isVideoAdsBlocked = this.checkVideoAds();

        // !<val> && - to stop checking if something already detected
        // function itself already stored result in class property
        // ads cookie blocked
        !this.updateAdsCookieBlocked() &&
            // ads fetch blocked
            !(await this.updateAdsFetchBlocked()) &&
            // ads blocked in window
            !(await this.updateAdsBlocked()) &&
            // ads removed from DOM
            !(await this.updateAdsRemoved()) &&
            // ads hidden from DOM
            !(await this.updateAdsHidden());
        devConsole('@ADBLOCKER_DETECTOR: update', {
            isAdblockerUsed: this.adBlockerUsed,
            chromiumAdblockersInstalled: this.chromiumAdblockersInstalled,
            adsCookieBlocked: this.adsCookieBlocked,
            adsFetchBlocked: this.adsFetchBlocked,
            adsBlocked: this.adsBlocked,
            adsRemoved: this.adsRemoved,
            adsHidden: this.adsHidden,
            isVideoAdsBlocked,
            // connection: window?.navigator?.connection,
        });
        const shouldBlock = this.shouldDoAdBlockerDetection(this.adBlockerUsed);

        if (shouldBlock) {
            // to set AdBlocker if calculation of "&& this.chromiumAdblockersInstalled" condition was too long (before call)
            (window as any)?.STORE?.dispatch?.(setGameState(GameState.ADBLOCK));
        }
    }

    /// DETECTORS

    public shouldCheckVideo() {
        return this.checkVideoAds && (window as any)?.STORE?.getState()?.gameState === GameState.PREROLL_PLAYING;
    }

    public checkVideoAds() {
        if (!this.shouldCheckVideo()) {
            return false;
        }

        const videoAdContainer = document.body.querySelector('video-ad-component');
        const videoAdIframe = videoAdContainer?.querySelector('#ark_video_container iframe');
        const videoAdPlayer = videoAdContainer?.querySelector('#ark_video_container video source');
        const videoAdFallback = videoAdContainer?.querySelector('.ark-video-fallback');
        const isBlocked =
            videoAdContainer &&
            (!(videoAdPlayer as any)?.src || !(videoAdIframe as any)?.src) &&
            !videoAdFallback?.innerHTML;

        devConsole('@ADBLOCKER_DETECTOR: adsVideoBlocked', isBlocked, {
            videoAdContainer,
            videoAdPlayer,
            videoAdIframe,
            videoAdFallback,
        });
        return videoAdContainer && isBlocked;
    }

    public updateAdsCookieBlocked(): boolean {
        this.adsCookieBlocked =
            this.chromiumAdblockersInstalled && // !important
            this.adsHidden && // to make less sensitive (detects AdBlock extension), but no sense to prevent when ads are displayed
            !Boolean(CookieService.getArkCookie(AD_BLOCKER_TRAP_FAKE_COOKIE));
        return this.adsCookieBlocked;
    }

    public async updateAdsFetchBlocked() {
        const _this = this;
        const existingAdsStylesUrl = 'https://a-ads.com';

        await fetch(existingAdsStylesUrl, { mode: 'no-cors' })
            .then((res) => {
                // if not good res and adblockers installed => ads fetch blocked
                _this.adsFetchBlocked =
                    _this.chromiumAdblockersInstalled && // !important
                    _this.adsHidden && // to make less sensitive (detects AdBlock extension), but no sense to prevent when ads are displayed
                    res.status !== 200
                        ? true
                        : false;
            })
            .catch((err) => {
                // if error (e.g. 30X - 301 - "blocked by client") and adblockers installed => ads fetch blocked
                _this.adsFetchBlocked = Boolean(err && _this.chromiumAdblockersInstalled && _this.adsHidden);
            });
        return this.adsFetchBlocked;
    }

    public async updateAdsBlocked() {
        if (this.adsBlocked === undefined) {
            this.adsBlocked = !(window as any).__ark_ads__;
        }

        devConsole('@ADBLOCKER_DETECTOR: updateAdsBlocked', this.adsBlocked);
        return this.adsBlocked;
    }

    public async updateAdsRemoved() {
        const adsContainers = document.getElementsByTagName('display-ad-component');

        if (this.adsShown && !adsContainers.length) {
            return true;
        }

        const problemsLog = [];

        this.interactionHold();
        this.adsRemoved =
            this.adsBlocked && // !important
            adsContainers &&
            adsContainers.length &&
            Array.from(adsContainers).every((adContainer) => {
                const adEl = adContainer.querySelector('.display-ad');
                const adFrame = adContainer.querySelector('iframe');
                const isRemoved = adContainer && (!adEl?.innerHTML || !adFrame?.src);

                if (isRemoved) {
                    problemsLog.push({
                        adEl,
                        adFrame,
                        adContainer,
                        adInnerHTML: adEl?.innerHTML,
                    });
                }

                return isRemoved;
            });
        this.interactionRelease();
        devConsole('@ADBLOCKER_DETECTOR: updateAdsRemoved', this.adsRemoved, {
            adsContainers,
            problemsLog,
        });
        return this.adsRemoved;
    }

    public async updateAdsHidden() {
        if (this.adsHidden === true) {
            return this.adsHidden; // already checked
        }

        const displayAds = document.querySelectorAll('div[class^="Ad-adContainer"]');

        this.interactionHold();
        const adsHiddenArr = displayAds ? Array.from(displayAds) : [];
        const problemsLog = [];

        this.adsHidden =
            adsHiddenArr &&
            adsHiddenArr.length &&
            (adsHiddenArr as Array<HTMLElement>).every((ad) => {
                // check if ad is hidden
                const computedStyle = window.getComputedStyle(ad);
                const boundingClientRect = ad.getBoundingClientRect();
                const isNotDisplayed = computedStyle.display === 'none';
                const isNotVisible = computedStyle.visibility === 'hidden';
                const isTransparent = computedStyle.opacity === '0';
                const isShrinked = boundingClientRect.width === 0 || boundingClientRect.height === 0;
                const isAdsHidden = isNotDisplayed || isNotVisible || isTransparent || isShrinked;

                if (isAdsHidden) {
                    problemsLog.push({
                        ad,
                        isAdsHidden,
                        isNotDisplayed,
                        isNotVisible,
                        isTransparent,
                        isShrinked,
                        boundingClientRectWidth: boundingClientRect.width,
                        boundingClientRectHeight: boundingClientRect.height,
                    });
                }

                return isAdsHidden;
            });
        this.interactionRelease();
        devConsole('@ADBLOCKER_DETECTOR: updateAdsHidden: ', this.adsHidden, {
            displayAds,
            adsBlocked: this.adsBlocked,
            adsHiddenArr,
            problemsLog,
        });
        return this.adsHidden;
    }

    /// HELPERS

    public isDesktop() {
        return !MiscUtils.isServer && window.innerWidth > 1024;
    }
    public isIncognitoMode: boolean = undefined;

    public async detectIncognitoMode() {
        if (this.isIncognitoMode !== undefined) {
            return this.isIncognitoMode;
        }

        try {
            const result = await detectIncognito();

            this.isIncognitoMode = result.isPrivate;
        } catch (error) {
            this.isIncognitoMode = false;
        }
    }

    public isGoodConnection(minSpeed: number = 1.2) {
        return (
            !MiscUtils.isServer && // not server
            (!(window as any)?.navigator?.connection?.downlink // not old browser
                ? true // assume good connection to make work e.g. in firefox
                : (window as any).navigator.connection.downlink >= minSpeed) // not slow connection (causes false positive when ads not loaded properly or ads.adRequest() method failed);
        );
    }

    get adsShown(): boolean {
        // @ts-ignore
        const { adFree, subscription } = !MiscUtils.isServer
            ? (window as any)?.STORE?.getState()?.preLoadData || {}
            : {};

        return !adFree && !subscription;
    }

    public interactionOnHold(ev) {
        ev.preventDefault();
        ev.stopPropagation();
    }

    public interactionHold() {
        window.addEventListener('scroll', this.interactionOnHold, true);
    }

    public interactionRelease() {
        window.removeEventListener('scroll', this.interactionOnHold.bind(this), true);
    }

    constructor() {
        this.interactionOnHold = this.interactionHold.bind(this);
    }
}

export const adBlockDetector = new AdBlockDetector();
