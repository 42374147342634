import React, { useCallback, useContext, useEffect, useState } from 'react';

import { UserSubscription } from '@arkadium/eagle-payments-api-client/dist/types/api/v1/dto/subscription.dto';
import { yupResolver } from '@hookform/resolvers/yup';
import classNames from 'classnames';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';


import styles from './SignIn.css';
import { emailValidation, validationSchema } from './validationSchemas/SignInSchema';
import { Input } from '../../../../atoms/HookFormInput/Input';
import { I18nText } from '../../../../atoms/i18nText/i18nText';
import { HeaderSideMenuTabs } from '../../../../constants/HeaderSideMenuTabs';
import { SignInUpComponents } from '../../../../constants/SignInUpComponents';
import { Button } from '../../../../FigmaStyleguide/Button/Button';
import { LS_COOKIE_CONSTS } from '../../../../models/Enums';
import { IGame } from '../../../../models/Game/Game';
import { RegistrationSource } from '../../../../models/User/RegistrationSource';
import CaptchaIframe from '../../../../molecules/Captcha/CaptchaIframe';
import useCaptcha, {
  RECAPTCHA_ACTIONS,
  RECAPTCHA_MODES
} from '../../../../molecules/Captcha/hooks/useCaptcha';
import { Divider } from '../../../../molecules/Divider/Divider';
import AdFreeService from '../../../../services/AdFreeService';
import adFreeService from '../../../../services/AdFreeService';
import { Analytics } from '../../../../services/Analytics/Analytics';
import { LEANPLUM_EVENTS, LeanplumAnalytics } from '../../../../services/Analytics/LeanplumAnalytics';
import { AppInsightService } from '../../../../services/AppInsight';
import { AuthDataContext } from '../../../../services/AuthDataReact';
import CollectionsService from '../../../../services/CollectionsService';
import { CookieService } from '../../../../services/CookieService';
import { GameService } from '../../../../services/GameService';
import { LocalStorageService } from '../../../../services/LocalStorage';
import PaymentService from '../../../../services/PaymentService';
import { UrlService } from '../../../../services/UrlService';
import UserService, { AuthType, EmailCodes } from '../../../../services/UserService';
import { setAuthValues } from '../../../../store/ducks/authData/authData';
import { setSignInUpState, setSnackbarData } from '../../../../store/ducks/layout';
import { activePageSelector } from '../../../../store/ducks/layoutSelectors';
import { setPreLoadData } from '../../../../store/ducks/preLoadData';
import { setShowRecaptcha } from '../../../../store/ducks/recaptcha';
import { setActiveUserSubscriptions } from '../../../../store/ducks/subscription/common';
import { GemsEffects } from '../../../../store/effects/gems.effects';
import { SocialButtons } from '../../../SocialButtons/SocialButtons';
import commonStyles from '../RightPanelCommonStyles/RightPanelCommonStyles.css';
import { RightPanelWrapper } from '../RightPanelWrapper/RightPanelWrapper';


type SignInProps = {
  onSignInUpFinished: () => unknown;
};

const SignIn = React.memo(({ onSignInUpFinished }: SignInProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const authDataContext = useContext(AuthDataContext);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors }
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(validationSchema),
    defaultValues: {
      email: authDataContext.value.email || '',
      password: authDataContext.value.password || ''
    }
  });
  const {
    iframeRef,
    iframeURL,
    setIsIframeCaptchaLoaded,
    getCaptchaToken,
    clearCaptchaData,
    showChallengeRecaptcha,
    captchaToken
  } = useCaptcha();
  const values = watch();
  const { email, password } = values;
  const [loading, setLoading] = useState<boolean>(false);
  const authType = useSelector(({ authValues }) => authValues.authType);
  const authValues = useSelector(({ authValues }) => authValues);
  const categoryPageName = useSelector(({ categoryPageName }) => categoryPageName);
  const gameArkadiumSlug = useSelector(({ gameArkadiumSlug }) => gameArkadiumSlug);
  const games = useSelector(({ games }) => games);
  const confirmSignUpCopy = useSelector(({ signInUpState }) => signInUpState.copyConfirmation);
  const socialRegistrationLocation = useSelector(({ socialRegistrationLocation }) => socialRegistrationLocation);
  const currentTab = useSelector(activePageSelector);
  const currentLang = useSelector(({ currentLang }) => currentLang);
  const plusFeatureFlag = useSelector(({ arkConfig }) => arkConfig?.plusFeature?.isEnabled);
  const game = gameArkadiumSlug ?
    GameService.findGameByArkadiumSlug(GameService.gameModelToGame(games), gameArkadiumSlug) :
    '';

  useEffect(() => {
    if (captchaToken && authType === AuthType.EmailPassword) {
      void fetchUserLogin();
    }
  }, [dispatch, captchaToken, authType]);

  const onSubmit = useCallback(() => {
    setLoading(true);
    void Analytics.trackEvent(Analytics.profile.loginClick(categoryPageName, game as IGame, RegistrationSource.EMAIL));
    dispatch(setAuthValues({ ...authValues, authType: AuthType.EmailPassword }));
    getCaptchaToken(RECAPTCHA_ACTIONS.SIGN_IN);
  }, [dispatch, setLoading]);
  const fetchUserLogin = async () => {
    try {
      await UserService.userLogin({
        email,
        password,
        captchaToken,
        captchaMode: showChallengeRecaptcha ? RECAPTCHA_MODES.CHALLENGE : undefined
      });
      handleUserLoginSuccess();
      dispatch(GemsEffects.UpdateGemsAmount());
    } catch (err) {
      handleLoginError(err);
    }
  };
  const handleUserLoginSuccess = useCallback(() => {
    LocalStorageService.setItem('authProvider', AuthType.EmailPassword);
    UserService.resetPurchaseFlow();
    void Analytics.trackEvent(
      Analytics.profile.loginSuccess(
        categoryPageName,
        game,
        socialRegistrationLocation,
        RegistrationSource.EMAIL
      )
    );
    LeanplumAnalytics.trackEvent(LEANPLUM_EVENTS.LOGIN_BTN, { action: 'login' });
    CollectionsService.synchronizeAnonymousUserCollections().catch((err) => {
      AppInsightService.trackAppError(err, { data: 'synchronizeAnonymousUserCollections()' });
    });
    void fetchUserSubscriptions();
    onSignInUpFinished();
    clearCaptchaData();
  }, [onSignInUpFinished]);
  const handleLoginError = useCallback((err: number) => {
    setLoading(false);

    if (err !== 1023) {
      clearCaptchaData();
    }

    // UserNotConfirmedError || UserEmailConfirmationCodeIsInvalid
    if (err === 1018 || err === 1019) {
      authDataContext.setAuthData({
        ...authDataContext.value,
        email,
        authProvider: AuthType.EmailPassword
      });
      dispatch(setSignInUpState(SignInUpComponents.REQUEST_ACTIVATION));
    } else {
      dispatch(
        setSnackbarData({
          isOpened: true,
          message: UserService.errorCodeToText(err),
          type: 'error',
          parentNode: 'rightSideMenu'
        })
      );
      dispatch(setShowRecaptcha(err === 1023));
    }
  }, [dispatch]);
  const fetchUserSubscriptions = async () => {
    try {
      const result = await PaymentService.getSubscriptions();

      handleUserSubscriptionsSuccess(result);
    } catch (err) {
      AppInsightService.trackAppError(err, { data: 'userLogin()' });
    }
  };
  const handleUserSubscriptionsSuccess = (result: UserSubscription[]) => {
    dispatch(setActiveUserSubscriptions(result));
    //Check subscription type
    const isUserSubscriber = UserService.isUserSubscriber();
    const isUserBonusOnly = UserService.isUserHasBonusOnly();

    CookieService.setArkCookie(LS_COOKIE_CONSTS.AD_FREE_VER, isUserBonusOnly);
    CookieService.setArkCookie(LS_COOKIE_CONSTS.SUBSCRIPTION, isUserSubscriber);

    if (isUserSubscriber) {
      CookieService.setArkCookie(
        LS_COOKIE_CONSTS.SUBSCRIPTION_ID,
        UserService.getSubscriptionId(),
        30
      );
      dispatch(
        setPreLoadData({
          adFree: adFreeService.adFreeTurnOffFilter(),
          subscription: isUserSubscriber
        })
      );
      AdFreeService.vignetteAdsFix();
    }
  };
  const getCopyBelow = () => {
    return (
      <p>
        <strong>{t('REGISTER.DONT_HAVE_PROFILE_BELOW')}</strong>
      </p>
    );
  };
  const fetchUserConfirmation = async (confirmationCode: string) => {
    try {
      await UserService.confirmUser({ code: confirmationCode });
      handleUserConfirmationSuccess();
      window.history.replaceState(null, '', encodeURI(UserService.generateUrl()));
    } catch (err) {
      dispatch(setSignInUpState(SignInUpComponents.REQUEST_ACTIVATION));
    }
  };
  const handleUserConfirmationSuccess = () => {
    const isWebviewMessage = UrlService.getQSParam(window.location.search, 'webview');

    dispatch(
      setSnackbarData({
        isOpened: true,
        message: isWebviewMessage
          ? t('REGISTER.CONFIRM_MAIL_MESSAGE_WEBVIEW')
          : t('REGISTER.CONFIRM_MAIL_MESSAGE'),
        type: 'success',
        parentNode: 'rightSideMenu'
      })
    );
    setLoading(false);
  };
  const fetchGetUserByEmail = async () => {
    try {
      const result = await UserService.getUserByEmail({ email });

      // set the data (name/email/avatar) to show it on the COMPLETE_SOCIAL_PROFILE screen
      authDataContext.setAuthData({ ...authDataContext.value, ...result });

      // if the user is registered through social networks,
      // but he has no password, then we show the COMPLETE_SOCIAL_PROFILE screen
      const authProvider = result.auth_provider_id[0];

      if (authProvider && !result.hasPassword) {
        dispatch(setSignInUpState(SignInUpComponents.COMPLETE_SOCIAL_PROFILE));
      }

      setLoading(false);
    } catch (err) {
      // set email to put this on the SIGN_UP screen
      authDataContext.setAuthData({ ...authDataContext.value, email });
      dispatch(setSignInUpState(SignInUpComponents.SIGN_UP));
      setLoading(false);
    }
  };
  const handleOnFormBlur = () => {
    if (emailValidation.isValidSync(email)) {
      void fetchGetUserByEmail();
      setLoading(true);
    }
  };

  useEffect(() => {
    void Analytics.trackEvent(Analytics.profile.loginImpression(categoryPageName, game as IGame));
    // if there is a query parameter with confirmation code to confirm the email
    const confirmationCode = UrlService.getQSParam(window.location.search, EmailCodes.CONFIRMATION_CODE);

    if (confirmationCode) {
      setLoading(true);
      void fetchUserConfirmation(confirmationCode);
    }

    window.history.replaceState(null, '', encodeURI(UserService.generateUrl()));
  }, []);

  let headerLabelKey: string;

  if (currentTab === HeaderSideMenuTabs.SHOP_TAB) {
    headerLabelKey = 'REGISTER.SIGN_IN_FOR_ACCESS_TO_GEMS_SHOP';
  } else if (confirmSignUpCopy) {
    headerLabelKey = 'REGISTER.SIGN_IN_ARKADIUM_PROFILE_2';
  } else {
    headerLabelKey = 'REGISTER.SIGN_IN_ARKADIUM_PROFILE';
  }

  return (
    <RightPanelWrapper
      headerLabelKey={headerLabelKey}
      topBlock={
        <img
          style={{ marginBottom: 20 }}
          src={`${UrlService.toCDNUrl('/images/signUpHeader_2.png')}`}
          alt="sign_up_avatars"
        />
      }
      fullWidth
    >
      <div className={commonStyles.subHeader}>
        <I18nText keyName={confirmSignUpCopy ? 'REGISTER.DONT_HAVE_PROFILE_2' : 'REGISTER.DONT_HAVE_PROFILE'} />
        {getCopyBelow()}
      </div>
      <div className={classNames({
        [styles.formWrapper]: !showChallengeRecaptcha,
        [styles.hideContent]: showChallengeRecaptcha
      })}
      >
        <form onSubmit={handleSubmit(onSubmit)} className={commonStyles.formContent} style={{ marginTop: 30 }}>
          <div className={styles.inputWrapper}>
            <Input
              required
              className={commonStyles.input}
              isValid={!errors.email}
              ariaInvalid={errors.email ? 'true' : 'false'}
              register={register}
              name="email"
              type="text"
              placeholder={t('SIGN_IN_INPUT_PLACEHOLDER_EMAIL')}
              value={email}
              showError={errors?.email}
              errorMessage={errors?.email?.message ?? ''}
              onBlur={handleOnFormBlur}
            />
          </div>
          <div className={styles.inputWrapper}>
            <Input
              required
              className={commonStyles.input}
              isValid={!errors.password}
              ariaInvalid={errors.password ? 'true' : 'false'}
              register={register}
              name="password"
              type="password"
              placeholder={t('SIGN_IN_INPUT_PLACEHOLDER_PASSWORD')}
              value={password}
              showError={errors?.password}
              errorMessage={errors?.password?.message ?? ''}
            />
          </div>
          <Button
            isLink
            className={styles.forgotPassword}
            onClick={() => dispatch(setSignInUpState(SignInUpComponents.RESET_PASSWORD))}
            type="button"
          >
            <I18nText keyName="REGISTER.FORGOT_PASSWORD" as="span" />
          </Button>
          <Button type="submit" disabled={loading} className={styles.submitButton} loading={loading}>
            <I18nText keyName="Submit" as="span" />
          </Button>
          <Divider className={styles.dividerBlock} />
          <SocialButtons
            captchaToken={captchaToken}
            clearCaptchaData={clearCaptchaData}
            getCaptchaToken={getCaptchaToken}
            showChallengeRecaptcha={showChallengeRecaptcha}
            className={styles.socialButtons}
            signIn
            game={game}
            category={categoryPageName}
            authType={authType}
            authValues={authValues}
            errorMessageSocial={(res) => {
              dispatch(
                setSnackbarData({
                  isOpened: true,
                  message: UserService.errorCodeToText(res),
                  type: 'error',
                  parentNode: 'rightSideMenu'
                })
              );
            }}
          />
          <div className={styles.policy}>
            <a target="_blank" href={UrlService.createPrivacyPolicyURL(currentLang, plusFeatureFlag)} rel="noreferrer noopener">
              <I18nText keyName={'REGISTER.PRIVACY_POLICY'} />
            </a>
          </div>
        </form>
      </div>
      <CaptchaIframe
        iframeRef={iframeRef}
        iframeURL={iframeURL}
        setIsIframeCaptchaLoaded={setIsIframeCaptchaLoaded}
      />
    </RightPanelWrapper>
  );
});

SignIn.displayName = 'SignIn';
export default SignIn;
