import React from 'react';

import styles from './HelpTabSuccess.css';
import { I18nText } from '../../../atoms/i18nText/i18nText';
import { UrlService } from '../../../services/UrlService';

export const HelpTabSuccess = React.memo(() => {
    return (
        <div className={styles.container}>
            <I18nText as="p" keyName="HELP_TAB.FORM_SUBMITTED" className={styles.statusMessageOk} />

            <I18nText as="p" keyName="HELP_TAB.FORM_THANKING" className={styles.thanking} />

            <img
                className={styles.image}
                src={UrlService.toCDNUrl('/illustrations/support/thank-you.png')}
                aria-hidden="true"
                alt="Thank you"
            />
        </div>
    );
});
