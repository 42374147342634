import React from 'react';

import { useDispatch } from 'react-redux';

import { I18nText } from '../../../../../atoms/i18nText/i18nText';
import { SignInUpComponents } from '../../../../../constants/SignInUpComponents';
import { Button } from '../../../../../FigmaStyleguide/Button/Button';
import { setSignInUpState } from '../../../../../store/ducks/layout';
import styles from '../../RightPanelCommonStyles/RightPanelCommonStyles.css';
import { RightPanelWrapper } from '../../RightPanelWrapper/RightPanelWrapper';
import { StillNeedHelp } from '../../StillNeedHelp/StillNeedHelp';

export const ResetPasswordSuccess = React.memo(() => {
  const dispatch = useDispatch();
  const handleSignIn = () => {
    dispatch(setSignInUpState(SignInUpComponents.SIGN_IN));
  };

  return (
    <RightPanelWrapper headerLabelKey="REGISTER.RESET_YOUR_PASSWORD_SUCCESS">
      <div className={styles.buttonRow}>
        <Button className={styles.submitButton} onClick={handleSignIn}>
          <I18nText keyName={'REGISTER.SIGN_IN'}/>
        </Button>
      </div>
      <StillNeedHelp/>
    </RightPanelWrapper>
  );
});
