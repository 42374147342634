import React from 'react';

import { useSelector } from 'react-redux';

import styles from './PlusAdvantageShopTab.css';
import { checkTrialPeriodAvailability, isTrialSubscriptionActive } from '../../../../../constants/SubscriptionPlan';
import {
	getFirstDateSubscription,
	getLastDateSubscription, getType,
	removeInternal
} from '../../../../../services/PaymentAndSubscriptionHelpers';
import {
	SubscriptionActions
} from '../../../../../templates/Profile/Tabs/PaymentAndSubscription/PaymentAndSubscription';
import {
	PlusAvatarCardBlock
} from '../../../../../templates/Profile/Tabs/PaymentAndSubscription/PlusPaymentAndSubscription/components/PlusAvatarCardBlock/PlusAvatarCardBlock';
import {
	PlusStatsBlock
} from '../../../../../templates/Profile/Tabs/PaymentAndSubscription/PlusPaymentAndSubscription/components/PlusStatsBlock/PlusStatsBlock';
import { StatusBlock } from '../../../../../templates/Profile/Tabs/PaymentAndSubscription/StatusBlock/StatusBlock';

export const PlusAdvantageShopTab = React.memo(() => {
	const activeSubscriptionFromRedux = useSelector(({ activeUserSubscriptions }) => activeUserSubscriptions);
	const activeSubscriptions = removeInternal(activeSubscriptionFromRedux);
	const expiredSubscriptionFromRedux = useSelector(({ expiredUserSubscriptions }) => expiredUserSubscriptions || []);
	const expiredSubscriptions = removeInternal(expiredSubscriptionFromRedux);
	const isAASubscription = activeSubscriptions.every(s => !s.planId.includes('aasub2025'));
	const isTrialAvailable = checkTrialPeriodAvailability(expiredSubscriptions);
	const isTrialActive = isTrialSubscriptionActive(activeSubscriptionFromRedux) && isTrialAvailable;
	const type = getType(activeSubscriptions);
	let avatarCardBlockDate: string;

	switch (type) {
		case SubscriptionActions.RENEW:
			avatarCardBlockDate = getLastDateSubscription(activeSubscriptions).endDate;
			break;
		case SubscriptionActions.RESTART:
			avatarCardBlockDate = getLastDateSubscription(expiredSubscriptions).endDate;
			break;
		case SubscriptionActions.STANDARD:
		case SubscriptionActions.SUBSCRIBED_BOTH:
		case SubscriptionActions.SUBSCRIBED_EXTERNAL:
		case SubscriptionActions.NONE:
		default:
			avatarCardBlockDate = getFirstDateSubscription(activeSubscriptions).startDate;
			break;
	}

	return (
		<div className={styles.container}>
			<PlusAvatarCardBlock type={type} date={avatarCardBlockDate} isTrialActive={isTrialActive} />
			<PlusStatsBlock type={type} isTrialActive={isTrialActive} isAASubscription={isAASubscription} />
			<StatusBlock
				date={getLastDateSubscription(activeSubscriptions).endDate}
				planId={getLastDateSubscription(activeSubscriptions).planId}
				isTrialActive={isTrialActive}
			/>
		</div>
	);
});
