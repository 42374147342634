import { reduxStore } from '../store';

export class TranslationService {
  static translateIt(key: string): string {
    const { texts } = reduxStore.store.getState();
    const entityField = key.split('.')[0];

    if (typeof texts[entityField] === 'object') {
      const entityValue = key.split('.')[1];

      return texts[entityField][entityValue];
    }

    if (key in texts) {
      return texts[key];
    }

    return key;
  }
}
