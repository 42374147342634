import React from 'react';

import { useTranslation } from "react-i18next";

import styles from './FAQBlock.css';

export const FAQBlock = React.memo(() => {
  const { t } = useTranslation();
  
    return (
        <div className={styles.container}>
          {t('FAQ_BLOCK.HAVE_QUESTIONS')}
          &nbsp;
            <a
                className={styles.link}
                href="https://support.arkadium.com/en/support/solutions/folders/44001245243"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="FAQ"
            >
              {t('FAQ_BLOCK.PLEASE_VISIT_FAQ')}
            </a>
        </div>
    );
});
