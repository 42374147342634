import { RouterState } from 'connected-react-router';
import { RouteComponentProps } from 'react-router';
import urlJoin from 'url-join';

import { PageService } from './PageService';
import { StringUtils } from '../../utils/StringUtils';
import { environment } from '../config/environment';
import { PageTypes } from '../constants/Pages';
import { QUERY_STRING_CONSTS } from '../models/Enums';
import {
    AboutUsPageLocalizedRoute,
    AllCategoriesLocalizedRoute,
    BlogArchiveLocalizedRoute,
    CategoryLocalizedRoute,
    CategoryPageSEO,
    CoBrandedPageLocalizedRoute,
    CoBrandedPageSEO,
    GameSubmissionDisclaimerPageRoute,
    GeneralPageSEO,
    GiftCardLocalizedRoute,
    SiteMapLocalizedRoute,
} from '../models/PagesData';
import { AppState, reduxStore } from '../store';

export const ROUTES = {
    Home: 'Home page',
    Category: 'category page',
    AllCategories: 'all categories page',
    Game: 'game page',
    Help: 'help page',
    Profile: 'profile page',
    Subscription: 'subscription page',
    GiftCard: 'gift card page',
    CoBranded: 'co-branded page',
    NotFound: '404 page',
};

// TODO: this condition is for test only of new game url routes, should remove afterwards
export const newRouteAllowedGames = ['free-online-jigsaw-puzzle', 'the-daily-jigsaw', 'jigsaw-blast'];

export interface UTMInfo {
  utmSource?: string;
  utmMedium?: string;
  utmCampaign?: string;
}

export class UrlService {
  public static get domain() {
    return window.location.host;
  }

  public static currentLang = 'en';

  public static historyPushStateFallback(url: string) {
    if (window.history.pushState && url.startsWith('/')) {
      window.history.pushState({ path: url }, '', url);
      window.history.go(0);
    } else {
      window.open(url, '_blank');
    }
  }

  public static getQSParam(queryString: string, name: string): string {
    if (name !== 'gameApi') {
      const search = new URLSearchParams(queryString.toLowerCase());

      return search.get(name.toLowerCase()) || '';
    } else {
      const search = new URLSearchParams(queryString);

      return search.get(name) || '';
    }
  }

  public static cleanupPathname(path: string): string {
    const { currentLang } = UrlService;
    const re = new RegExp(`^(\/${currentLang}\/)`, 'mgi');

    return path.replace(re, '/');
  }

  public static cleanupUrl(url: string): string {
    const { currentLang } = UrlService;
    const re = new RegExp(`(\/${currentLang}\/)`, 'mgi');

    return url.replace(re, '/');
  }

  public static createURL(path: string, omitLang = false, ignoreBase = true, omitFr = false): string {
    const { currentLang } = UrlService;
    const root = ignoreBase ? '/' : environment.SITE_BASE_URL;

    if (
      currentLang === 'en' ||
      currentLang === 'es' ||
      currentLang === 'de' ||
      omitLang ||
      (omitFr ? currentLang === 'fr' : false)
    ) {
      return urlJoin(root, path);
    }

    return urlJoin(root, UrlService.currentLang, path);
  }

  // TODO: this condition is for test only of new game url routes, should remove afterwards
  public static createGameURL(slug: string, prevGame = ''): string {
    const { currentLang } = UrlService;
    let allowedGame = false;

    newRouteAllowedGames.forEach((s) => {
      if (StringUtils.equalIgnoreCase(slug, s)) {
        allowedGame = true;
      }
    });

    const newAllowedPath =
            currentLang && currentLang === 'fr'
      ? `/jeux-gratuits/puzzles/${slug}/${prevGame}`
        : `/free-online-games/jigsaw-puzzles/${slug}/${prevGame}`;

        if (allowedGame) {
        return UrlService.createURL(newAllowedPath, undefined, true);
      }

      return UrlService.createURL(`/games/${slug}/${prevGame}`, undefined, true);
  }

  public static getPageLocalizedRoute(lang: string, pageType: PageTypes): string {
    switch (pageType) {
      case PageTypes.Category:
        return CategoryLocalizedRoute[lang];
      case PageTypes.AllCategories:
        return AllCategoriesLocalizedRoute[lang];
      case PageTypes.GiftCard:
        return GiftCardLocalizedRoute[lang];
      case PageTypes.AboutUs:
        return AboutUsPageLocalizedRoute[lang];
      case PageTypes.Dynamic:
        return CoBrandedPageLocalizedRoute[lang];
      case PageTypes.BlogArchive:
        return BlogArchiveLocalizedRoute[lang];
      case PageTypes.GameSubmissionDisclaimer:
        return GameSubmissionDisclaimerPageRoute[lang];
      case PageTypes.SiteMap:
        return SiteMapLocalizedRoute[lang];

      default:
        throw new Error(
          `${pageType}, Unsupported page type received. Pls provide supported page type or extend this method to support new page type.`
        );
    }
  }

  public static buildAbsoluteUrl(routerProps: RouteComponentProps | RouterState) {
    return UrlService.createURL(UrlService.cleanupPathname(routerProps.location.pathname));
  }

  public static getCustomVersionParam(routerProps: RouteComponentProps | RouterState): string {
    return UrlService.getQSParam(routerProps.location.search, 'version');
  }

  public static getGameAPIParam(routerProps: RouteComponentProps | RouterState): string {
    return UrlService.getQSParam(routerProps.location.search, 'gameApi');
  }

  public static getPromoParam(routerProps: RouteComponentProps | RouterState) {
    return UrlService.getQSParam(routerProps.location.search, 'arkpromo');
  }

  public static getUtmInfo(): UTMInfo {
    return {
      utmSource: UrlService.getQSParam(window.location.search, QUERY_STRING_CONSTS.UTM_SOURCE),
      utmMedium: UrlService.getQSParam(window.location.search, QUERY_STRING_CONSTS.UTM_MEDIUM),
      utmCampaign: UrlService.getQSParam(window.location.search, QUERY_STRING_CONSTS.UTM_CAMPAIGN)
    } as UTMInfo;
  }

  public static getPageType(storeState?: AppState): string {
    const pageTypeStore = (storeState ? storeState : reduxStore.store.getState())?.pageType;

    if (!pageTypeStore) {
      return 'Type not defined';
    }
    /* eslint-disable-next-line */
    for (const route in ROUTES) {
      if (pageTypeStore === route) {
        return ROUTES[route];
      }
    }

    // fallback raw type from PageTypes enum
    if (pageTypeStore) {
      return pageTypeStore;
    }
  }

  public static getPageNameForAnalytics(): string {
    const { pageType, categoryPageName } = reduxStore.store.getState();

    if (pageType === 'Home') {
      return 'home page';
    }

    if (pageType === 'Category') {
      const mismatchedCategories = {
        AllCategories: 'all games',
        Brain: 'brain-games',
        Board: 'board-games',
        'Hidden Object': 'hidden-object',
        Match3: 'match-3-games',
        // TODO: this condition is for test only of new game url routes, should remove afterwards
        Jigsaws: 'game'
      };

      if (mismatchedCategories[categoryPageName]) {
        return mismatchedCategories[categoryPageName];
      }

      return categoryPageName.toLowerCase();
    }

    return pageType.toLowerCase();
  }

  public static toCDNUrl(path: string): string {
    if (path && path.startsWith('http')) {
      return path;
    }

    return UrlService.getPublicPath() + path;
  }

  static get domainWithoutWWW() {
    return window.location.host.replace('www.', '');
  }

  static convertEntities(url: string) {
    return url.replace('&amp;', '&');
  }

  static updateImageHostToFacebookValid(url: string) {
    if (!url) {
      return url;
    }

    return url.replace('arenacloud.cdn.arkadiumhosted.com', 'www.arkadium.com');
  }

  // TODO: !!! Need refactoring right after Subscription finish for user subscribe or not ALL STATES !!!
  // USE CASE Check If user is Subscriber but he chose not a premium avatar
  // Check Avatar list in panel
  static toAvatarLink(avatarFileName: string, extension: string): string {
    const DEFAULT_AVATAR_FILENAME = `avatar_blank`;
    /**
     * Need to check do we have such avatar name, if not set default avatar.
     * Arena uses 'avatar-99.png' naming convention,
     * Arkadium.com uses 'avatar_99.png'
     * So we can check symbol on index 6 to find out is it valid for Arkom avatar name
     */
    const checkIsAvatarNameValid = (avatarFileName: string) => {
      if (!avatarFileName) {
        return DEFAULT_AVATAR_FILENAME;
      }

      if (avatarFileName[6] !== '_') {
        return DEFAULT_AVATAR_FILENAME;
      }

      return avatarFileName?.split('.').slice(0, -1).join('.');
    };
    const avatarName = checkIsAvatarNameValid(avatarFileName);
    // TODO: Calculate every time! Not SAFE at all, check string for occurrences of strings
    const isPremiumFilename = avatarName.includes('premium');
    const PATH_TO_STORAGE = `https://arenacloud.cdn.arkadiumhosted.com/arenaxstorage-blob/arenax-index/_arena-shared-content_/arkadium-avatars/`;
    let AVATAR_PATH = `${PATH_TO_STORAGE}subscription/${extension}/1x/${avatarName}.${extension}`;

    if (isPremiumFilename) {
      AVATAR_PATH = `${PATH_TO_STORAGE}subscription/${extension}/1x/premium/${avatarName}.${extension}`;
    }

    return AVATAR_PATH;
  }

  static toFrameLink(frameFileName: string, extension: string): string {
    const DEFAULT_FRAME = `frame2`;
    const frameName = frameFileName?.split('.').slice(0, -1).join('.');

    return `https://arenacloud.cdn.arkadiumhosted.com/arenaxstorage-blob/arenax-index/_arena-shared-content_/arkadium-frames/${extension}/1x/${
      frameName || DEFAULT_FRAME
    }.${extension}`;
  }

  static getSlugFromPathname(pathname: string) {
    return this.cleanupUrl(pathname).replace(/\//g, '');
  }

  static getSlugFromFullPath(pathname: string) {
    return this.cleanupUrl(pathname).split('/').filter(Boolean).pop();
  }

  static generateHomeLinkForAffiliate(pages: (GeneralPageSEO | CategoryPageSEO | CoBrandedPageSEO)[]) {
    const slug = PageService.getPageSlugByAffiliateCookie(pages);

    return slug ? UrlService.createURL(slug, false, true, true) : UrlService.createURL('/');
  }

  static createStrapiMediaUrl(mediaUrl: string) {
    if (environment.STRAPI_URL.includes('localhost') || environment.STRAPI_URL.includes('127.0.0.1')) {
      return `${environment.STRAPI_URL}${mediaUrl}`;
    }

    return mediaUrl;
  }

  static checkIfAbsoluteUrlOrCreate(url: string): string {
    if (/^https?:\/\//i.test(url)) {
      return url;
    }

    return UrlService.createURL(url);
  }

  static getGameSlugFromSearchParam(url: string): string {
    const searchParams = new URLSearchParams(url);

    return searchParams?.get('game');
  }

  static removeCountryCodeFromUrlBeginning(path: string) {
    const allowedCountryCodes = ['us'];
    const countryCodesPattern = allowedCountryCodes.join('|');
    const countryCodeRegex = new RegExp(`^/(${countryCodesPattern})/`, 'i');

    return path.replace(countryCodeRegex, '/');
  }

  static getPublicPath () {
    return BUILD_ENV !== 'local' && BUILD_ENV !== 'branch'
        ? `https://arenacloud.cdn.arkadiumhosted.com/arkadiummodulesstorage-blob/arkcom-release/${BUILD_ENV}/${ADO_BUILD_ID}`
        : '';
  }

  static createPrivacyPolicyURL(currentLang: string, plusFeatureFlag: boolean): string {
    if (currentLang !== 'en') {
      if (plusFeatureFlag) {
        return this.createURL('privacy-policy.pdf');
      }

      return this.createURL('legacy-documents/privacy-policy.pdf')
    }

    return this.createURL('privacy-policy/');
  }
}
