import React from 'react';

import classNames from 'classnames';

import styles from './PlusTabButton.css';
import { Button } from '../../../../../FigmaStyleguide/Button/Button';

type TabButtonProps = {
  tabKey: string;
  onClick: (e?: any) => void;
  className: string;
  active: boolean;
  children?: React.ReactNode;
};

export const PlusTabButton = React.forwardRef<HTMLButtonElement, TabButtonProps>(
  ({ tabKey, onClick, className, children, active }, ref) => {
    return (
      <Button
        ref={ref}
        id={`${tabKey}-tab`}
        useDefaultStyles={false}
        className={classNames(
          styles.tabItem,
          {
            [styles.active]: active
          },
          styles[className]
        )}
        role="tab"
        onClick={onClick}
        focusable={active}
        ariaSelected={active}
        dataElementDescription="tab-button"
        ariaControls={`${tabKey}-tabpanel`}
      >
        {children}
      </Button>
    );
  }
);
