import React, { FC } from 'react';

import { useTranslation } from "react-i18next";

import styles from './IconsBlock.css';
import { AdsIcon } from '../../../../../FigmaStyleguide/Icons/AdsIcon';
import { CustomAvatar } from '../../../../../FigmaStyleguide/Icons/CustomAvatars';
import { DiscountIcon } from '../../../../../FigmaStyleguide/Icons/DiscountIcon';

const IconsBlock: FC = React.memo(() => {
  const { t } = useTranslation();
  
  return (
    <div className={styles.container}>
      <div className={styles.point}>
        <div className={styles.iconBlock} />
        <AdsIcon className={styles.icon} />
        {t('ICONS_BLOCK.NO_DISRUPTIVE_ADS')}
      </div>
      <div className={styles.point}>
        <div className={styles.iconBlock} />
        <DiscountIcon className={styles.icon} />
        {t('ICONS_BLOCK.PURCHASES_DISCOUNT')}
      </div>
      <div className={styles.point}>
        <div className={styles.iconBlock} />
        <CustomAvatar className={styles.icon} />
        {t('ICONS_BLOCK.SUBSCRIBER_ONLY')}
      </div>
    </div>
  );
});

IconsBlock.displayName = 'IconsBlock';

export default IconsBlock;
